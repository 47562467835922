import styled from "styled-components"
import React from "react"

const CircleBox = styled.div`
  width: 35px;
  height: 50px;
  margin: 0.2rem !important;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 0;
  .progress {
    position: absolute;
    height: auto;
    width: 60px;
    cursor: pointer;
    margin-bottom: 0px !important;
  }

  .progress-circle {
    transform: rotate(-90deg);
    margin-top: 0px;
  }

  .progress-circle-bg {
    fill: none;
    stroke: #d2d2d2;
    stroke-width: 5px;
  }
  .progress-circle-value {
    fill: none;
    stroke: ${({ color }) => color};
    stroke-width: 5px;
    stroke-dasharray: 100 100;
    stroke-dashoffset: ${({ point }) => 100 - point * 62.5};
    transition: stroke-dashoffset 0.7s ease-in-out;
  }
`

const CircularProgressBar = ({ point, max, color }) => {
  const result = point / max
  return (
    <CircleBox point={result} color={color}>
      <div className="progress">
        <svg
          className="progress-circle"
          width="50px"
          height="50px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="progress-circle-bg"
            cx="26"
            cy="26"
            r="10"
          ></circle>
          <circle
            className="progress-circle-value update-value"
            cx="26"
            cy="26"
            r="10"
          ></circle>
        </svg>
      </div>
    </CircleBox>
  )
}

export default CircularProgressBar
