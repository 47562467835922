import { faInfoCircle, faUserGraduate } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled, { css } from "styled-components"
import H4 from "../partials/Headers/H4"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

const Wrapper = styled.aside`
  padding-bottom: 2rem;
  padding-top: 1.5rem;
  margin: 3rem 0;
  ${(props) => {
    switch (props.color) {
      case "green":
        return css`
        border-top: 0.4rem solid rgb(58, 147, 68);
        border-bottom: 0.4rem solid rgb(58, 147, 68);
        background: rgb(237, 248, 239) none repeat scroll 0% 0%;
          }
        `
      default:
        return css`
        border-top: 0.4rem solid rgb(78, 132, 178);
        border-bottom: 0.4rem solid rgb(78, 132, 178);
        background: rgb(237, 242, 248);
          }
        `
    }
  }}
`

const StyledIcon = styled(FontAwesomeIcon)`
  vertical-align: middle;
  margin-right: 1rem;
  margin-left: 0.5rem;
  color: var(--color);
`

const Header = styled.div`
  padding: 1.5rem 2rem 0.5rem 2rem;
  /*   background: #B1A9F7; */
  height: 80px;
  display: flex;
  align-content: center;

  H4 {
    color: #333;
    font-weight: 600;
    margin-bottom\: 0 !important;
    font-family: 'Poppins', sans-serif;
  }
`

const Body = styled.div`
  padding: 0rem 2rem;
`

const variantToColor = {
  hint: "#528afc",
  learningObjectives: "#57b181",
}

const variantToIcon = {
  hint: faInfoCircle,
  learningObjectives: faUserGraduate,
}

const TextBox = (props) => {
  return (
    <Wrapper color={props.color}>
      <Header>
        <H4>{props.title}</H4>
      </Header>
      <Body>{props.children}</Body>
    </Wrapper>
  )
}

export default withSimpleErrorBoundary(TextBox)
