import { CourseStatusProvider } from "moocfi-quizzes"
import React from "react"
import styled from "styled-components"
import courseSettings from "../../../course-settings"
import LoginStateContext, {
  withLoginStateContext,
} from "../../contexes/LoginStateContext"
import { accessToken } from "../../services/moocfi"
import CourseProgressVisualization from "./CourseProgressVisualization"
import ExerciseVisualization from "./ExerciseVisualization"

const Wrapper = styled.div`
  background: #f1f1f1;
  padding-top: 4rem;
  padding-bottom: 2rem;
  font-family: "Josefin Sans", sans-serif;
  text-transform: uppercase;
`
const StyledDiv = styled.div`
  display: none;
  visibility: hidden;
`

const CourseProgress = () => {
  const { loggedIn } = React.useContext(LoginStateContext)
  if (!loggedIn) {
    return null
  }
  return (
    <CourseStatusProvider
      accessToken={accessToken()}
      courseId={courseSettings.courseId}
      languageId={courseSettings.language}
    >
      <Wrapper>
        <CourseProgressVisualization />
        <StyledDiv>
          <ExerciseVisualization />
        </StyledDiv>
      </Wrapper>
    </CourseStatusProvider>
  )
}

export default withLoginStateContext(CourseProgress)
