const publicPemKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA4HitGQqzRVBj/v50C82Q
qTM5pukyUcdZlrm+/FVhNKwWjkXZdADqHG2ChjIiTGyM8nr3RsorOFR80co5AAnb
dUXDS7jNLMxwqU0QZ7XWtS5pZUID5gSXogkbkInd0K0r5x9W5DVUqgNDQ1afr+80
/A2wwt34E7rKS7XdRfURfMrxQHRflafieFFwO3DaMKRAH5HgTwZ5Lnbhivu/+f5D
mKpoGgyiFdvUgiLU+oXA9K1zSditkD/RMxZnsEmJjXkvOU4uZajYq+I7Ixq0aQqA
NHiYpWe4qI4rtZ7MRK0UgrfAKVOJ71tfBaeRc0ZLgRsiTksRwQdjYW9/WWhFdsLz
fT3TYTqMn5kx5OJItzpksRnIbWlPnqGN+RDBf0S/d4BUKOjcuRgm2rhEjONAcxvd
x2KHQe2Jljy1LL0X4ytsoSd3G9JTLbD96q92gp4y/ry66im+W5eO3AmrCezNPGQV
WQb62fYAfqOmUyMBhDfFrv2rx+n98Qjtkk2QTkAWFaf3G5p8FA/zw6qRxWUQevHK
ARPWAarfKdWIzyb+7upQNem2dxbar/I9k7NWxLXrNDynayEJWgnZtuHrF+mhcB1T
qKf3ZhSvL0rzpgtH+bs6kQcgfZUQqx6t/2qkv/cl4xQzKBwD7PhGtLxzKZ+YjikA
86Eix/5a4VkB2CdHn6j6YU0CAwEAAQ==
-----END PUBLIC KEY-----`

function str2ab(str) {
  const buf = new ArrayBuffer(str.length)
  const bufView = new Uint8Array(buf)
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i)
  }
  return buf
}

function ab2str(buf) {
  return String.fromCharCode.apply(null, new Uint8Array(buf))
}

function importRsaKey(pem) {
  // fetch the part of the PEM string between header and footer
  const pemHeader = "-----BEGIN PUBLIC KEY-----"
  const pemFooter = "-----END PUBLIC KEY-----"
  const pemContents = pem
    .substring(pemHeader.length, pem.length - pemFooter.length)
    .replace(/(\r\n|\n|\r)/gm, "")
  // base64 decode the string to get the binary data
  const binaryDerString = atob(pemContents)
  // convert from a binary string to an ArrayBuffer
  const binaryDer = str2ab(binaryDerString)
  const check = ab2str(binaryDer)
  if (check !== binaryDerString) {
    throw new Error("String to array buffer not reversible")
  }

  return window.crypto.subtle.importKey(
    "spki",
    binaryDer,
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    true,
    ["encrypt"],
  )
}

export default async function encrypt(message) {
  const publicKey = await importRsaKey(publicPemKey)
  const data = new TextEncoder().encode(message)
  const encrypted = await window.crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
      hash: "SHA-256",
    },
    publicKey,
    data,
  )
  const encryptedString = btoa(ab2str(encrypted))

  return encryptedString
}
