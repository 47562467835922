import React, { useLayoutEffect, useState } from "react"
import { animated, useSpring, interpolate } from "react-spring"
import styled, { keyframes } from "styled-components"
import Score from "../../assets/score.svg"
import { respond } from "../../_respond"

const fade = keyframes`
0% {
  opacity: 1;
}

50% {
  opacity: 0.2;
}

100% {
  opacity: 1;
}
`

const dark = keyframes`
0% {
  stroke: #7AF8DC ;
}

50% {
  stroke: #4C8CDA;
}

100% {
  stroke: #7AF8DC;
}
`

const shade = keyframes`
0% {
  stroke: #EFC356;
}

50% {
  stroke: #7AF8DC;
}

100% {
  stroke: #EFC356;
}
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const pulse = keyframes`
0% {
  transform: scale(0);
  opacity: 1;
  transform-origin: center;
}
100% {
  transform: scale(4.5);
  opacity: 0;
  transform-origin: center;
}
`

const Container = styled.div`
  text-align: center;
  position: relative;
  margin-top: 3rem;

  ${respond.mobile`
  margin-bottom: 3rem;
  `}

  div {
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -48%);
    font-size: 3.5rem;
    font-weight: 500;
    font-family: "Josefin Sans", sans-serif;
    opacity: 0.9;
  }

  span {
    display: block;
    font-size: 0.8rem;
    line-height: 1;
  }
`

const StyledSVG = styled(Score)`
  .cross-1 {
    animation: ${fade} infinite 3s linear;
  }
  .cross-2 {
    animation: ${fade} infinite 4s linear;
  }
  .cross-3 {
    animation: ${fade} infinite 5s linear;
  }

  .hollow-1 {
    animation: ${dark} infinite 4s ease-in-out;
  }
  .hollow-2 {
    animation: ${fade} infinite 3s ease-in-out;
  }
  .hollow-3 {
    animation: ${shade} infinite 5s ease-in-out;
  }
`

const ScoreBoard = ({ point, max }) => {
  const [willAnimate, setWillAnimate] = useState(false)

  useLayoutEffect(() => {
    const onScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight
      if (scrollPosition > 1700) {
        setWillAnimate(true)
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  const props = useSpring({
    number: !willAnimate ? 0 : point,
    config: { duration: 1000 },
  })

  return (
    <Container>
      <StyledSVG alt="Kurssipisteet" />
      <animated.div>
        {props.number.interpolate((number) => Math.floor(number))}
      </animated.div>
    </Container>
  )
}

export default ScoreBoard
