import LinearProgress from "@material-ui/core/LinearProgress"
import { withStyles } from "@material-ui/core/styles"
import React from "react"
/* import { LinearProgress } from "@material-ui/core"; */
import styled from "styled-components"

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 16,
    borderRadius: 10,
    marginBottom: theme.spacing(3),
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 300 : 700],
  },
  bar: {
    borderRadius: 10,
    backgroundColor: "#33BF9F",
  },
}))(LinearProgress)
/* const StyledLinearProgress = styled(LinearProgress)`
  height: 30px;
  flex: 1;
  position: relative;
  bottom: -4px;
  border-radius: 100px;
  background: #E9F0F1;
`

const LinearProgressContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`
 */

const Label = styled.div`
  min-width: 125px;
  font-weight: 500;
  font-family: "Josefin Sans", sans-serif;
  margin-right: 1rem;
  display: grid;
  margin-bottom: 0.2rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;

  span:first-of-type {
    justify-self: start;
    font-size: 2em;
    font-weight: 500;
    padding: 0 0 0.4rem 0;
  }

  span:last-child {
    justify-self: end;
    font-weight: 700;
    font-size: 0.8em;
    opacity: 0.8;
  }
`

const ProgresssBar = ({ n, exercisesDone, exercisesTotal }) => {
  const exerciseScaled = (exercisesDone / exercisesTotal) * 100
  return (
    <div>
      <Label>
        <span>
          {n
            ? Math.round(exerciseScaled) + "% tehty"
            : `${exercisesDone} / ${exercisesTotal}`}
        </span>
        <span></span>{" "}
      </Label>
      <BorderLinearProgress
        variant="determinate"
        value={100 && exerciseScaled}
      />
    </div>
  )
}

export default ProgresssBar
