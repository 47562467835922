export const data = [
  {
    name: "kuvio-7.1",
    height: 500,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "",
    yDomain: [0, 160000],
    xDomain: ["dataMin", "dataMax"],
    type: ["number", "number"], // [xAxis, yAxis]
    xAxisOffset: 5,
    yAxisOffset: 0,
    minTickGapX: 9,
    xTickCount: 15,
    yTickCount: 15,
    yAxisWidth: 70,
    xAxisHeight: 50,
    margins: [15, 25, 0, 5],
    tickDx: 0,
    fontSize: "0.8rem",
    xTickFontSize: "1rem",
    manualTicksX: [
      "1820",
      "1840",
      "1860",
      "1880",
      "1900",
      "1920",
      "1940",
      "1960",
      "1980",
      "2000",
    ],
    names: [
      "Kivihiili",
      "Aurinkoenergia",
      "Öljy",
      "Maakaasu",
      "Biomassa",
      "Muut uusiutuvat",
      "Vesivoima",
      "Ydinvoima",
      "Tuulivoima",
    ],
    colorPicker: [
      "#1a1c1a",
      "#e0f000",
      "#575e57",
      "#0a3309",
      "#206e20",
      "#64e864",
      "#0025e0",
      "#1c916e",
      "#4ddb4b",
      "#444d46",
      "#46635e",
    ],
    data: [
      {
        name: 1800,
        area1: 97,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 5556,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1810,
        area1: 128,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 5833,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1820,
        area1: 153,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 6111,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1830,
        area1: 264,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 6389,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1840,
        area1: 356,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 6944,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1850,
        area1: 569,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 7222,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1860,
        area1: 1061,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 6944,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1870,
        area1: 1642,
        area2: 0,
        area3: 6,
        area4: 0,
        area5: 6944,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1880,
        area1: 2542,
        area2: 0,
        area3: 33,
        area4: 0,
        area5: 6944,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1890,
        area1: 3856,
        area2: 0,
        area3: 89,
        area4: 33,
        area5: 6667,
        area6: 0,
        area7: 14,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1900,
        area1: 5728,
        area2: 0,
        area3: 181,
        area4: 64,
        area5: 6111,
        area6: 0,
        area7: 17,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1910,
        area1: 8656,
        area2: 0,
        area3: 397,
        area4: 142,
        area5: 6389,
        area6: 0,
        area7: 33,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1920,
        area1: 9833,
        area2: 0,
        area3: 889,
        area4: 233,
        area5: 6944,
        area6: 0,
        area7: 64,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1930,
        area1: 10125,
        area2: 0,
        area3: 1756,
        area4: 603,
        area5: 7222,
        area6: 0,
        area7: 131,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1940,
        area1: 11586,
        area2: 0,
        area3: 2653,
        area4: 875,
        area5: 7222,
        area6: 0,
        area7: 192,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1950,
        area1: 12603,
        area2: 0,
        area3: 5444,
        area4: 2092,
        area5: 7500,
        area6: 0,
        area7: 333,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1960,
        area1: 15442,
        area2: 0,
        area3: 11097,
        area4: 4472,
        area5: 8889,
        area6: 0,
        area7: 689,
        area8: 0,
        area9: 0,
        area10: 0,
      },
      {
        name: 1965,
        area1: 16140,
        area2: 0,
        area3: 18109,
        area4: 6303,
        area5: 9162,
        area6: 18,
        area7: 923,
        area8: 26,
        area9: 0,
        area10: 0,
      },
      {
        name: 1966,
        area1: 16324,
        area2: 0,
        area3: 19496,
        area4: 6869,
        area5: 9218,
        area6: 20,
        area7: 984,
        area8: 34,
        area9: 0,
        area10: 0,
      },
      {
        name: 1967,
        area1: 16061,
        area2: 0,
        area3: 20891,
        area4: 7375,
        area5: 9274,
        area6: 20,
        area7: 1006,
        area8: 41,
        area9: 0,
        area10: 0,
      },
      {
        name: 1968,
        area1: 16301,
        area2: 0,
        area3: 22675,
        area4: 8044,
        area5: 9331,
        area6: 22,
        area7: 1059,
        area8: 52,
        area9: 0,
        area10: 0,
      },
      {
        name: 1969,
        area1: 16799,
        area2: 0,
        area3: 24577,
        area4: 8833,
        area5: 9387,
        area6: 23,
        area7: 1122,
        area8: 62,
        area9: 0,
        area10: 0,
      },
      {
        name: 1970,
        area1: 17059,
        area2: 0,
        area3: 26708,
        area4: 9614,
        area5: 9444,
        area6: 26,
        area7: 1175,
        area8: 79,
        area9: 0,
        area10: 0,
      },
      {
        name: 1971,
        area1: 16966,
        area2: 0,
        area3: 28205,
        area4: 10292,
        area5: 9499,
        area6: 28,
        area7: 1227,
        area8: 110,
        area9: 0,
        area10: 0,
      },
      {
        name: 1972,
        area1: 17154,
        area2: 0,
        area3: 30378,
        area4: 10861,
        area5: 9553,
        area6: 30,
        area7: 1285,
        area8: 152,
        area9: 0,
        area10: 0,
      },
      {
        name: 1973,
        area1: 17668,
        area2: 0,
        area3: 32746,
        area4: 11378,
        area5: 9608,
        area6: 32,
        area7: 1303,
        area8: 204,
        area9: 0,
        area10: 0,
      },
      {
        name: 1974,
        area1: 17682,
        area2: 0,
        area3: 32272,
        area4: 11656,
        area5: 9663,
        area6: 34,
        area7: 1431,
        area8: 267,
        area9: 0,
        area10: 0,
      },
      {
        name: 1975,
        area1: 18025,
        area2: 0,
        area3: 31948,
        area4: 11661,
        area5: 9718,
        area6: 34,
        area7: 1449,
        area8: 370,
        area9: 0,
        area10: 0,
      },
      {
        name: 1976,
        area1: 18688,
        area2: 0,
        area3: 34030,
        area4: 12356,
        area5: 9774,
        area6: 38,
        area7: 1443,
        area8: 433,
        area9: 0,
        area10: 0,
      },
      {
        name: 1977,
        area1: 19241,
        area2: 0,
        area3: 35215,
        area4: 12761,
        area5: 9830,
        area6: 41,
        area7: 1492,
        area8: 539,
        area9: 0,
        area10: 0,
      },
      {
        name: 1978,
        area1: 19458,
        area2: 0,
        area3: 36426,
        area4: 13294,
        area5: 9886,
        area6: 43,
        area7: 1614,
        area8: 626,
        area9: 0,
        area10: 0,
      },
      {
        name: 1979,
        area1: 20364,
        area2: 0,
        area3: 37024,
        area4: 14119,
        area5: 9943,
        area6: 46,
        area7: 1695,
        area8: 651,
        area9: 0,
        area10: 0,
      },
      {
        name: 1980,
        area1: 20858,
        area2: 0,
        area3: 35577,
        area4: 14239,
        area5: 10000,
        area6: 49,
        area7: 1732,
        area8: 712,
        area9: 0,
        area10: 0,
      },
      {
        name: 1981,
        area1: 21150,
        area2: 0,
        area3: 34296,
        area4: 14394,
        area5: 10106,
        area6: 53,
        area7: 1769,
        area8: 841,
        area9: 0,
        area10: 0,
      },
      {
        name: 1982,
        area1: 21384,
        area2: 0,
        area3: 33198,
        area4: 14469,
        area5: 10213,
        area6: 61,
        area7: 1801,
        area8: 912,
        area9: 0,
        area10: 0,
      },
      {
        name: 1983,
        area1: 22045,
        area2: 0,
        area3: 32969,
        area4: 14703,
        area5: 10321,
        area6: 68,
        area7: 1878,
        area8: 1034,
        area9: 0,
        area10: 0,
      },
      {
        name: 1984,
        area1: 23000,
        area2: 0,
        area3: 33739,
        area4: 15903,
        area5: 10430,
        area6: 76,
        area7: 1941,
        area8: 1255,
        area9: 0,
        area10: 0,
      },
      {
        name: 1985,
        area1: 23986,
        area2: 0,
        area3: 33789,
        area4: 16261,
        area5: 10541,
        area6: 78,
        area7: 1980,
        area8: 1489,
        area9: 0,
        area10: 0,
      },
      {
        name: 1986,
        area1: 24256,
        area2: 0,
        area3: 34803,
        area4: 16419,
        area5: 10653,
        area6: 86,
        area7: 2007,
        area8: 1595,
        area9: 0,
        area10: 0,
      },
      {
        name: 1987,
        area1: 25210,
        area2: 0,
        area3: 35499,
        area4: 17281,
        area5: 10765,
        area6: 92,
        area7: 2034,
        area8: 1735,
        area9: 0,
        area10: 0,
      },
      {
        name: 1988,
        area1: 25965,
        area2: 0,
        area3: 36703,
        area4: 18089,
        area5: 10879,
        area6: 94,
        area7: 2099,
        area8: 1891,
        area9: 0,
        area10: 0,
      },
      {
        name: 1989,
        area1: 26213,
        area2: 0,
        area3: 37300,
        area4: 18869,
        area5: 10995,
        area6: 104,
        area7: 2088,
        area8: 1945,
        area9: 3,
        area10: 0,
      },
      {
        name: 1990,
        area1: 25895,
        area2: 0,
        area3: 37691,
        area4: 19483,
        area5: 11111,
        area6: 117,
        area7: 2159,
        area8: 2000,
        area9: 4,
        area10: 91,
      },
      {
        name: 1991,
        area1: 25643,
        area2: 1,
        area3: 37691,
        area4: 19975,
        area5: 11243,
        area6: 122,
        area7: 2209,
        area8: 2096,
        area9: 4,
        area10: 102,
      },
      {
        name: 1992,
        area1: 25550,
        area2: 0,
        area3: 38344,
        area4: 20067,
        area5: 11376,
        area6: 131,
        area7: 2209,
        area8: 2112,
        area9: 5,
        area10: 98,
      },
      {
        name: 1993,
        area1: 25675,
        area2: 1,
        area3: 38099,
        area4: 20269,
        area5: 11511,
        area6: 135,
        area7: 2341,
        area8: 2185,
        area9: 6,
        area10: 100,
      },
      {
        name: 1994,
        area1: 25774,
        area2: 1,
        area3: 38935,
        area4: 20394,
        area5: 11647,
        area6: 140,
        area7: 2357,
        area8: 2226,
        area9: 7,
        area10: 111,
      },
      {
        name: 1995,
        area1: 25954,
        area2: 1,
        area3: 39445,
        area4: 21108,
        area5: 11785,
        area6: 146,
        area7: 2486,
        area8: 2322,
        area9: 8,
        area10: 115,
      },
      {
        name: 1996,
        area1: 26572,
        area2: 1,
        area3: 40380,
        area4: 22164,
        area5: 11925,
        area6: 151,
        area7: 2520,
        area8: 2407,
        area9: 9,
        area10: 115,
      },
      {
        name: 1997,
        area1: 26527,
        area2: 1,
        area3: 41413,
        area4: 22033,
        area5: 12066,
        area6: 161,
        area7: 2566,
        area8: 2390,
        area9: 12,
        area10: 132,
      },
      {
        name: 1998,
        area1: 26360,
        area2: 1,
        area3: 41624,
        area4: 22439,
        area5: 12209,
        area6: 169,
        area7: 2587,
        area8: 2431,
        area9: 16,
        area10: 126,
      },
      {
        name: 1999,
        area1: 26522,
        area2: 1,
        area3: 42371,
        area4: 23075,
        area5: 12414,
        area6: 177,
        area7: 2606,
        area8: 2524,
        area9: 21,
        area10: 122,
      },
      {
        name: 2000,
        area1: 27417,
        area2: 1,
        area3: 42897,
        area4: 24000,
        area5: 12500,
        area6: 186,
        area7: 2652,
        area8: 2581,
        area9: 31,
        area10: 116,
      },
      {
        name: 2001,
        area1: 27853,
        area2: 1,
        area3: 43278,
        area4: 24331,
        area5: 12500,
        area6: 192,
        area7: 2584,
        area8: 2654,
        area9: 38,
        area10: 127,
      },
      {
        name: 2002,
        area1: 28945,
        area2: 2,
        area3: 43639,
        area4: 25053,
        area5: 12470,
        area6: 206,
        area7: 2632,
        area8: 2696,
        area9: 52,
        area10: 149,
      },
      {
        name: 2003,
        area1: 31497,
        area2: 2,
        area3: 44610,
        area4: 25753,
        area5: 12329,
        area6: 218,
        area7: 2628,
        area8: 2642,
        area9: 63,
        area10: 185,
      },
      {
        name: 2004,
        area1: 33664,
        area2: 3,
        area3: 46256,
        area4: 26736,
        area5: 12160,
        area6: 235,
        area7: 2821,
        area8: 2762,
        area9: 85,
        area10: 209,
      },
      {
        name: 2005,
        area1: 36171,
        area2: 4,
        area3: 46824,
        area4: 27464,
        area5: 12076,
        area6: 255,
        area7: 2916,
        area8: 2769,
        area9: 104,
        area10: 246,
      },
      {
        name: 2006,
        area1: 38071,
        area2: 6,
        area3: 47367,
        area4: 28175,
        area5: 11993,
        area6: 272,
        area7: 3028,
        area8: 2803,
        area9: 133,
        area10: 313,
      },
      {
        name: 2007,
        area1: 40224,
        area2: 8,
        area3: 47958,
        area4: 29325,
        area5: 11911,
        area6: 295,
        area7: 3079,
        area8: 2746,
        area9: 171,
        area10: 424,
      },
      {
        name: 2008,
        area1: 40770,
        area2: 13,
        area3: 47566,
        area4: 30025,
        area5: 11829,
        area6: 316,
        area7: 3257,
        area8: 2738,
        area9: 221,
        area10: 574,
      },
      {
        name: 2009,
        area1: 40149,
        area2: 21,
        area3: 46654,
        area4: 29411,
        area5: 11747,
        area6: 340,
        area7: 3252,
        area8: 2699,
        area9: 276,
        area10: 636,
      },
      {
        name: 2010,
        area1: 41997,
        area2: 34,
        area3: 48087,
        area4: 31606,
        area5: 11667,
        area6: 380,
        area7: 3436,
        area8: 2769,
        area9: 347,
        area10: 734,
      },
      {
        name: 2011,
        area1: 44018,
        area2: 65,
        area3: 48550,
        area4: 32372,
        area5: 11553,
        area6: 399,
        area7: 3501,
        area8: 2653,
        area9: 441,
        area10: 775,
      },
      {
        name: 2012,
        area1: 44185,
        area2: 101,
        area3: 49157,
        area4: 33219,
        area5: 11441,
        area6: 431,
        area7: 3650,
        area8: 2471,
        area9: 531,
        area10: 789,
      },
      {
        name: 2013,
        area1: 44993,
        area2: 139,
        area3: 49689,
        area4: 33767,
        area5: 11330,
        area6: 464,
        area7: 3814,
        area8: 2493,
        area9: 636,
        area10: 857,
      },
      {
        name: 2014,
        area1: 44954,
        area2: 198,
        area3: 50014,
        area4: 33994,
        area5: 11220,
        area6: 501,
        area7: 3894,
        area8: 2541,
        area9: 706,
        area10: 933,
      },
      {
        name: 2015,
        area1: 43844,
        area2: 257,
        area3: 50892,
        area4: 34781,
        area5: 11111,
        area6: 538,
        area7: 3885,
        area8: 2576,
        area9: 832,
        area10: 937,
      },
      {
        name: 2016,
        area1: 43196,
        area2: 328,
        area3: 51920,
        area4: 35589,
        area5: 11111,
        area6: 548,
        area7: 4013,
        area8: 2615,
        area9: 964,
        area10: 970,
      },
      {
        name: 2017,
        area1: 43360,
        area2: 447,
        area3: 52568,
        area4: 36586,
        area5: 11111,
        area6: 583,
        area7: 4066,
        area8: 2639,
        area9: 1141,
        area10: 1012,
      },
      {
        name: 2018,
        area1: 44109,
        area2: 583,
        area3: 53181,
        area4: 38517,
        area5: 11111,
        area6: 615,
        area7: 4171,
        area8: 2700,
        area9: 1270,
        area10: 1109,
      },
      {
        name: 2019,
        area1: 43849,
        area2: 724,
        area3: 53620,
        area4: 39292,
        area5: 11111,
        area6: 652,
        area7: 4222,
        area8: 2796,
        area9: 1430,
        area10: 1143,
      },
    ],
  },
  {
    name: "kuvio-7.5",
    height: 500,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "%",
    yDomain: [0, 40],
    xDomain: ["dataMin", "dataMax"],
    yAxisOffset: 0,
    xAxisOffset: 5,
    type: ["number", "number"], // [xAxis, yAxis]
    manualTicksY: [0, 5, 10, 15, 20, 25, 30, 35, 40],
    yTickCount: 8,
    xTickCount: 8,
    xAxisHeight: 50,
    yAxisWidth: 50,
    manualTicksX: [
      "1820",
      "1840",
      "1860",
      "1880",
      "1900",
      "1920",
      "1940",
      "1960",
      "1980",
      "2000",
      "2020",
    ],
    names: [
      "Euroopan unioni",
      "Muu Eurooppa",
      "Yhdysvallat",
      "Muu Amerikka",
      "Afrikka",
      "Intia",
      "Kiina",
      "Muu Aasia ja Tyynenmeren alue",
      "Kv liikenne",
    ],
    colorPicker: [
      "#001eff",
      "#ff8c00",
      "#9e9e9e",
      "#ebb400",
      "#00a2ff",
      "#00ba22",
      "#2a32c7",
      "#f8ff3b",
      "#757567",
      "#444d46",
      "#46635e",
    ],
    data: [
      {
        name: 1820,
        area1: 0,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1821,
        area1: 0,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1822,
        area1: 0,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1823,
        area1: 0,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1824,
        area1: 0,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1825,
        area1: 0,
        area2: 0,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1826,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1827,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1828,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1829,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1830,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1831,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1832,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1833,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1834,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1835,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1836,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1837,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1838,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1839,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1840,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1841,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1842,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1843,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1844,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1845,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1846,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1847,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1848,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1849,
        area1: 0,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1850,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1851,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1852,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1853,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1854,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1855,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1856,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1857,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1858,
        area1: 0.1,
        area2: 0.1,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1859,
        area1: 0.1,
        area2: 0.2,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1860,
        area1: 0.1,
        area2: 0.2,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1861,
        area1: 0.1,
        area2: 0.2,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1862,
        area1: 0.1,
        area2: 0.2,
        area3: 0,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1863,
        area1: 0.1,
        area2: 0.2,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1864,
        area1: 0.2,
        area2: 0.2,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1865,
        area1: 0.2,
        area2: 0.2,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1866,
        area1: 0.2,
        area2: 0.2,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1867,
        area1: 0.2,
        area2: 0.2,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1868,
        area1: 0.2,
        area2: 0.2,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1869,
        area1: 0.2,
        area2: 0.2,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1870,
        area1: 0.2,
        area2: 0.2,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1871,
        area1: 0.2,
        area2: 0.2,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1872,
        area1: 0.2,
        area2: 0.3,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1873,
        area1: 0.3,
        area2: 0.3,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1874,
        area1: 0.2,
        area2: 0.3,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1875,
        area1: 0.3,
        area2: 0.3,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1876,
        area1: 0.3,
        area2: 0.3,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1877,
        area1: 0.3,
        area2: 0.3,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1878,
        area1: 0.3,
        area2: 0.3,
        area3: 0.1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1879,
        area1: 0.3,
        area2: 0.3,
        area3: 0.2,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1880,
        area1: 0.3,
        area2: 0.3,
        area3: 0.2,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1881,
        area1: 0.3,
        area2: 0.3,
        area3: 0.2,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1882,
        area1: 0.3,
        area2: 0.3,
        area3: 0.2,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1883,
        area1: 0.4,
        area2: 0.3,
        area3: 0.3,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1884,
        area1: 0.4,
        area2: 0.3,
        area3: 0.3,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1885,
        area1: 0.4,
        area2: 0.3,
        area3: 0.3,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1886,
        area1: 0.4,
        area2: 0.3,
        area3: 0.3,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1887,
        area1: 0.4,
        area2: 0.3,
        area3: 0.3,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1888,
        area1: 0.4,
        area2: 0.4,
        area3: 0.4,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1889,
        area1: 0.5,
        area2: 0.4,
        area3: 0.3,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1890,
        area1: 0.5,
        area2: 0.4,
        area3: 0.4,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1891,
        area1: 0.5,
        area2: 0.4,
        area3: 0.4,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1892,
        area1: 0.5,
        area2: 0.4,
        area3: 0.5,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1893,
        area1: 0.5,
        area2: 0.4,
        area3: 0.5,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1894,
        area1: 0.5,
        area2: 0.4,
        area3: 0.4,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1895,
        area1: 0.5,
        area2: 0.4,
        area3: 0.5,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1896,
        area1: 0.6,
        area2: 0.4,
        area3: 0.5,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1897,
        area1: 0.6,
        area2: 0.4,
        area3: 0.5,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1898,
        area1: 0.6,
        area2: 0.4,
        area3: 0.5,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1899,
        area1: 0.7,
        area2: 0.5,
        area3: 0.6,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1900,
        area1: 0.7,
        area2: 0.5,
        area3: 0.7,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0,
        area9: 0,
      },
      {
        name: 1901,
        area1: 0.7,
        area2: 0.5,
        area3: 0.7,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1902,
        area1: 0.7,
        area2: 0.5,
        area3: 0.8,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1903,
        area1: 0.7,
        area2: 0.5,
        area3: 0.9,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1904,
        area1: 0.8,
        area2: 0.5,
        area3: 0.9,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1905,
        area1: 0.8,
        area2: 0.5,
        area3: 1,
        area4: 0,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1906,
        area1: 0.8,
        area2: 0.5,
        area3: 1,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1907,
        area1: 0.9,
        area2: 0.6,
        area3: 1.2,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1908,
        area1: 1,
        area2: 0.6,
        area3: 1.1,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1909,
        area1: 1,
        area2: 0.6,
        area3: 1.2,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1910,
        area1: 1,
        area2: 0.6,
        area3: 1.3,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1911,
        area1: 1,
        area2: 0.6,
        area3: 1.3,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1912,
        area1: 1.1,
        area2: 0.6,
        area3: 1.3,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1913,
        area1: 1.1,
        area2: 0.6,
        area3: 1.4,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1914,
        area1: 1,
        area2: 0.6,
        area3: 1.3,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1915,
        area1: 0.9,
        area2: 0.6,
        area3: 1.4,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1916,
        area1: 0.9,
        area2: 0.6,
        area3: 1.5,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1917,
        area1: 0.9,
        area2: 0.6,
        area3: 1.7,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1918,
        area1: 0.9,
        area2: 0.5,
        area3: 1.7,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1919,
        area1: 0.7,
        area2: 0.5,
        area3: 1.5,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1920,
        area1: 0.9,
        area2: 0.5,
        area3: 1.7,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1921,
        area1: 0.9,
        area2: 0.4,
        area3: 1.4,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1922,
        area1: 0.9,
        area2: 0.5,
        area3: 1.4,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1923,
        area1: 0.8,
        area2: 0.5,
        area3: 1.9,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.1,
        area9: 0,
      },
      {
        name: 1924,
        area1: 1,
        area2: 0.5,
        area3: 1.7,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1925,
        area1: 1,
        area2: 0.5,
        area3: 1.8,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1926,
        area1: 1,
        area2: 0.3,
        area3: 1.9,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1927,
        area1: 1.1,
        area2: 0.6,
        area3: 1.9,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1928,
        area1: 1.1,
        area2: 0.6,
        area3: 1.8,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1929,
        area1: 1.2,
        area2: 0.6,
        area3: 2,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1930,
        area1: 1.1,
        area2: 0.6,
        area3: 1.7,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1931,
        area1: 1,
        area2: 0.6,
        area3: 1.5,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1932,
        area1: 0.9,
        area2: 0.6,
        area3: 1.3,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1933,
        area1: 0.9,
        area2: 0.6,
        area3: 1.3,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1934,
        area1: 1,
        area2: 0.7,
        area3: 1.4,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0,
        area8: 0.2,
        area9: 0,
      },
      {
        name: 1935,
        area1: 1,
        area2: 0.7,
        area3: 1.5,
        area4: 0.1,
        area5: 0,
        area6: 0,
        area7: 0.1,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1936,
        area1: 1.1,
        area2: 0.8,
        area3: 1.7,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0.1,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1937,
        area1: 1.2,
        area2: 0.8,
        area3: 1.8,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0.1,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1938,
        area1: 1.2,
        area2: 0.8,
        area3: 1.5,
        area4: 0.2,
        area5: 0,
        area6: 0.1,
        area7: 0.1,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1939,
        area1: 1.3,
        area2: 0.8,
        area3: 1.7,
        area4: 0.2,
        area5: 0,
        area6: 0,
        area7: 0.1,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1940,
        area1: 1.3,
        area2: 0.9,
        area3: 1.9,
        area4: 0.2,
        area5: 0,
        area6: 0.1,
        area7: 0.1,
        area8: 0.4,
        area9: 0,
      },
      {
        name: 1941,
        area1: 1.3,
        area2: 0.8,
        area3: 2,
        area4: 0.2,
        area5: 0.1,
        area6: 0.1,
        area7: 0.1,
        area8: 0.4,
        area9: 0,
      },
      {
        name: 1942,
        area1: 1.3,
        area2: 0.7,
        area3: 2.2,
        area4: 0.2,
        area5: 0.1,
        area6: 0.1,
        area7: 0.1,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1943,
        area1: 1.3,
        area2: 0.7,
        area3: 2.3,
        area4: 0.2,
        area5: 0.1,
        area6: 0,
        area7: 0.1,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1944,
        area1: 1.1,
        area2: 0.7,
        area3: 2.4,
        area4: 0.2,
        area5: 0.1,
        area6: 0,
        area7: 0.1,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1945,
        area1: 0.5,
        area2: 0.7,
        area3: 2.4,
        area4: 0.2,
        area5: 0.1,
        area6: 0.1,
        area7: 0.1,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1946,
        area1: 0.8,
        area2: 0.8,
        area3: 2.3,
        area4: 0.3,
        area5: 0.1,
        area6: 0.1,
        area7: 0,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1947,
        area1: 1,
        area2: 0.9,
        area3: 2.5,
        area4: 0.3,
        area5: 0.1,
        area6: 0.1,
        area7: 0,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1948,
        area1: 1.1,
        area2: 1,
        area3: 2.6,
        area4: 0.3,
        area5: 0.1,
        area6: 0.1,
        area7: 0,
        area8: 0.3,
        area9: 0,
      },
      {
        name: 1949,
        area1: 1.2,
        area2: 1,
        area3: 2.2,
        area4: 0.3,
        area5: 0.1,
        area6: 0.1,
        area7: 0.1,
        area8: 0.4,
        area9: 0,
      },
      {
        name: 1950,
        area1: 1.3,
        area2: 1.1,
        area3: 2.5,
        area4: 0.3,
        area5: 0.1,
        area6: 0.1,
        area7: 0.1,
        area8: 0.4,
        area9: 0.1,
      },
      {
        name: 1951,
        area1: 1.4,
        area2: 1.2,
        area3: 2.6,
        area4: 0.4,
        area5: 0.1,
        area6: 0.1,
        area7: 0.1,
        area8: 0.4,
        area9: 0.2,
      },
      {
        name: 1952,
        area1: 1.5,
        area2: 1.2,
        area3: 2.6,
        area4: 0.4,
        area5: 0.1,
        area6: 0.1,
        area7: 0.1,
        area8: 0.4,
        area9: 0.2,
      },
      {
        name: 1953,
        area1: 1.5,
        area2: 1.3,
        area3: 2.6,
        area4: 0.4,
        area5: 0.1,
        area6: 0.1,
        area7: 0.1,
        area8: 0.4,
        area9: 0.2,
      },
      {
        name: 1954,
        area1: 1.6,
        area2: 1.4,
        area3: 2.5,
        area4: 0.4,
        area5: 0.1,
        area6: 0.1,
        area7: 0.2,
        area8: 0.4,
        area9: 0.2,
      },
      {
        name: 1955,
        area1: 1.7,
        area2: 1.5,
        area3: 2.7,
        area4: 0.4,
        area5: 0.1,
        area6: 0.1,
        area7: 0.2,
        area8: 0.5,
        area9: 0.2,
      },
      {
        name: 1956,
        area1: 1.9,
        area2: 1.6,
        area3: 2.9,
        area4: 0.4,
        area5: 0.1,
        area6: 0.1,
        area7: 0.2,
        area8: 0.5,
        area9: 0.2,
      },
      {
        name: 1957,
        area1: 1.9,
        area2: 1.7,
        area3: 2.8,
        area4: 0.5,
        area5: 0.1,
        area6: 0.1,
        area7: 0.3,
        area8: 0.6,
        area9: 0.2,
      },
      {
        name: 1958,
        area1: 1.9,
        area2: 1.7,
        area3: 2.7,
        area4: 0.5,
        area5: 0.1,
        area6: 0.1,
        area7: 0.5,
        area8: 0.6,
        area9: 0.2,
      },
      {
        name: 1959,
        area1: 1.9,
        area2: 1.8,
        area3: 2.8,
        area4: 0.5,
        area5: 0.1,
        area6: 0.1,
        area7: 0.7,
        area8: 0.6,
        area9: 0.2,
      },
      {
        name: 1960,
        area1: 2.1,
        area2: 1.9,
        area3: 2.9,
        area4: 0.5,
        area5: 0.2,
        area6: 0.1,
        area7: 0.8,
        area8: 0.7,
        area9: 0.2,
      },
      {
        name: 1961,
        area1: 2.2,
        area2: 1.9,
        area3: 2.9,
        area4: 0.5,
        area5: 0.2,
        area6: 0.1,
        area7: 0.6,
        area8: 0.8,
        area9: 0.3,
      },
      {
        name: 1962,
        area1: 2.4,
        area2: 2,
        area3: 3,
        area4: 0.5,
        area5: 0.2,
        area6: 0.1,
        area7: 0.5,
        area8: 0.9,
        area9: 0.3,
      },
      {
        name: 1963,
        area1: 2.5,
        area2: 2.1,
        area3: 3.1,
        area4: 0.5,
        area5: 0.2,
        area6: 0.1,
        area7: 0.5,
        area8: 0.9,
        area9: 0.3,
      },
      {
        name: 1964,
        area1: 2.6,
        area2: 2.2,
        area3: 3.3,
        area4: 0.6,
        area5: 0.2,
        area6: 0.1,
        area7: 0.5,
        area8: 1,
        area9: 0.3,
      },
      {
        name: 1965,
        area1: 2.7,
        area2: 2.3,
        area3: 3.4,
        area4: 0.6,
        area5: 0.2,
        area6: 0.2,
        area7: 0.5,
        area8: 1.1,
        area9: 0.3,
      },
      {
        name: 1966,
        area1: 2.8,
        area2: 2.4,
        area3: 3.6,
        area4: 0.6,
        area5: 0.2,
        area6: 0.2,
        area7: 0.5,
        area8: 1.2,
        area9: 0.3,
      },
      {
        name: 1967,
        area1: 2.8,
        area2: 2.4,
        area3: 3.7,
        area4: 0.7,
        area5: 0.2,
        area6: 0.2,
        area7: 0.5,
        area8: 1.4,
        area9: 0.4,
      },
      {
        name: 1968,
        area1: 3,
        area2: 2.5,
        area3: 3.8,
        area4: 0.7,
        area5: 0.3,
        area6: 0.2,
        area7: 0.5,
        area8: 1.5,
        area9: 0.4,
      },
      {
        name: 1969,
        area1: 3.2,
        area2: 2.6,
        area3: 4,
        area4: 0.8,
        area5: 0.3,
        area6: 0.2,
        area7: 0.6,
        area8: 1.7,
        area9: 0.4,
      },
      {
        name: 1970,
        area1: 3.3,
        area2: 2.8,
        area3: 4.3,
        area4: 0.9,
        area5: 0.3,
        area6: 0.2,
        area7: 0.8,
        area8: 1.9,
        area9: 0.4,
      },
      {
        name: 1971,
        area1: 3.4,
        area2: 2.9,
        area3: 4.4,
        area4: 0.9,
        area5: 0.3,
        area6: 0.2,
        area7: 0.9,
        area8: 2,
        area9: 0.5,
      },
      {
        name: 1972,
        area1: 3.5,
        area2: 3,
        area3: 4.6,
        area4: 1,
        area5: 0.4,
        area6: 0.2,
        area7: 1,
        area8: 2.1,
        area9: 0.5,
      },
      {
        name: 1973,
        area1: 3.7,
        area2: 3.1,
        area3: 4.8,
        area4: 1,
        area5: 0.4,
        area6: 0.2,
        area7: 1,
        area8: 2.3,
        area9: 0.5,
      },
      {
        name: 1974,
        area1: 3.7,
        area2: 3.1,
        area3: 4.6,
        area4: 1,
        area5: 0.4,
        area6: 0.2,
        area7: 1,
        area8: 2.4,
        area9: 0.5,
      },
      {
        name: 1975,
        area1: 3.6,
        area2: 3.3,
        area3: 4.5,
        area4: 1.1,
        area5: 0.4,
        area6: 0.2,
        area7: 1.2,
        area8: 2.4,
        area9: 0.4,
      },
      {
        name: 1976,
        area1: 3.9,
        area2: 3.4,
        area3: 4.7,
        area4: 1.1,
        area5: 0.4,
        area6: 0.2,
        area7: 1.2,
        area8: 2.5,
        area9: 0.4,
      },
      {
        name: 1977,
        area1: 3.9,
        area2: 3.5,
        area3: 4.9,
        area4: 1.1,
        area5: 0.4,
        area6: 0.3,
        area7: 1.3,
        area8: 2.7,
        area9: 0.4,
      },
      {
        name: 1978,
        area1: 4,
        area2: 3.6,
        area3: 4.9,
        area4: 1.2,
        area5: 0.5,
        area6: 0.3,
        area7: 1.5,
        area8: 2.7,
        area9: 0.5,
      },
      {
        name: 1979,
        area1: 4.1,
        area2: 3.6,
        area3: 5,
        area4: 1.3,
        area5: 0.5,
        area6: 0.3,
        area7: 1.5,
        area8: 2.8,
        area9: 0.5,
      },
      {
        name: 1980,
        area1: 4.1,
        area2: 3.7,
        area3: 4.8,
        area4: 1.3,
        area5: 0.5,
        area6: 0.3,
        area7: 1.5,
        area8: 2.9,
        area9: 0.4,
      },
      {
        name: 1981,
        area1: 3.9,
        area2: 3.6,
        area3: 4.7,
        area4: 1.3,
        area5: 0.5,
        area6: 0.3,
        area7: 1.5,
        area8: 2.8,
        area9: 0.4,
      },
      {
        name: 1982,
        area1: 3.8,
        area2: 3.6,
        area3: 4.4,
        area4: 1.3,
        area5: 0.6,
        area6: 0.3,
        area7: 1.6,
        area8: 2.8,
        area9: 0.4,
      },
      {
        name: 1983,
        area1: 3.7,
        area2: 3.7,
        area3: 4.4,
        area4: 1.3,
        area5: 0.6,
        area6: 0.4,
        area7: 1.7,
        area8: 2.9,
        area9: 0.4,
      },
      {
        name: 1984,
        area1: 3.8,
        area2: 3.7,
        area3: 4.7,
        area4: 1.3,
        area5: 0.7,
        area6: 0.4,
        area7: 1.8,
        area8: 3,
        area9: 0.4,
      },
      {
        name: 1985,
        area1: 3.8,
        area2: 4,
        area3: 4.7,
        area4: 1.3,
        area5: 0.7,
        area6: 0.4,
        area7: 2,
        area8: 3.1,
        area9: 0.4,
      },
      {
        name: 1986,
        area1: 3.8,
        area2: 4,
        area3: 4.7,
        area4: 1.3,
        area5: 0.7,
        area6: 0.4,
        area7: 2.1,
        area8: 3.2,
        area9: 0.4,
      },
      {
        name: 1987,
        area1: 3.8,
        area2: 4,
        area3: 4.8,
        area4: 1.4,
        area5: 0.7,
        area6: 0.5,
        area7: 2.3,
        area8: 3.3,
        area9: 0.5,
      },
      {
        name: 1988,
        area1: 3.8,
        area2: 4.1,
        area3: 5.1,
        area4: 1.4,
        area5: 0.7,
        area6: 0.5,
        area7: 2.4,
        area8: 3.6,
        area9: 0.5,
      },
      {
        name: 1989,
        area1: 3.8,
        area2: 4,
        area3: 5.1,
        area4: 1.5,
        area5: 0.7,
        area6: 0.5,
        area7: 2.5,
        area8: 3.7,
        area9: 0.6,
      },
      {
        name: 1990,
        area1: 3.9,
        area2: 4.2,
        area3: 5.1,
        area4: 1.5,
        area5: 0.7,
        area6: 0.6,
        area7: 2.5,
        area8: 3.9,
        area9: 0.6,
      },
      {
        name: 1991,
        area1: 3.8,
        area2: 3.9,
        area3: 5.1,
        area4: 1.5,
        area5: 0.7,
        area6: 0.6,
        area7: 2.6,
        area8: 4,
        area9: 0.6,
      },
      {
        name: 1992,
        area1: 3.7,
        area2: 3.4,
        area3: 5.2,
        area4: 1.5,
        area5: 0.7,
        area6: 0.7,
        area7: 2.7,
        area8: 4.2,
        area9: 0.6,
      },
      {
        name: 1993,
        area1: 3.6,
        area2: 3.2,
        area3: 5.3,
        area4: 1.5,
        area5: 0.7,
        area6: 0.7,
        area7: 2.9,
        area8: 4.3,
        area9: 0.6,
      },
      {
        name: 1994,
        area1: 3.6,
        area2: 2.8,
        area3: 5.4,
        area4: 1.6,
        area5: 0.7,
        area6: 0.7,
        area7: 3.1,
        area8: 4.4,
        area9: 0.6,
      },
      {
        name: 1995,
        area1: 3.6,
        area2: 2.8,
        area3: 5.4,
        area4: 1.6,
        area5: 0.8,
        area6: 0.8,
        area7: 3.4,
        area8: 4.5,
        area9: 0.6,
      },
      {
        name: 1996,
        area1: 3.7,
        area2: 2.7,
        area3: 5.6,
        area4: 1.7,
        area5: 0.8,
        area6: 0.8,
        area7: 3.5,
        area8: 4.6,
        area9: 0.7,
      },
      {
        name: 1997,
        area1: 3.7,
        area2: 2.6,
        area3: 5.7,
        area4: 1.8,
        area5: 0.8,
        area6: 0.9,
        area7: 3.5,
        area8: 4.7,
        area9: 0.7,
      },
      {
        name: 1998,
        area1: 3.6,
        area2: 2.6,
        area3: 5.7,
        area4: 1.9,
        area5: 0.8,
        area6: 0.9,
        area7: 3.4,
        area8: 4.6,
        area9: 0.8,
      },
      {
        name: 1999,
        area1: 3.6,
        area2: 2.6,
        area3: 5.8,
        area4: 1.9,
        area5: 0.8,
        area6: 1,
        area7: 3.4,
        area8: 4.8,
        area9: 0.8,
      },
      {
        name: 2000,
        area1: 3.6,
        area2: 2.5,
        area3: 6,
        area4: 1.9,
        area5: 0.9,
        area6: 1,
        area7: 3.4,
        area8: 5.1,
        area9: 0.8,
      },
      {
        name: 2001,
        area1: 3.7,
        area2: 2.6,
        area3: 5.9,
        area4: 1.9,
        area5: 0.9,
        area6: 1,
        area7: 3.5,
        area8: 5.2,
        area9: 0.8,
      },
      {
        name: 2002,
        area1: 3.7,
        area2: 2.6,
        area3: 5.9,
        area4: 2,
        area5: 0.9,
        area6: 1,
        area7: 3.9,
        area8: 5.3,
        area9: 0.8,
      },
      {
        name: 2003,
        area1: 3.7,
        area2: 2.6,
        area3: 6,
        area4: 2,
        area5: 1,
        area6: 1.1,
        area7: 4.5,
        area8: 5.5,
        area9: 0.8,
      },
      {
        name: 2004,
        area1: 3.8,
        area2: 2.7,
        area3: 6.1,
        area4: 2,
        area5: 1,
        area6: 1.1,
        area7: 5.2,
        area8: 5.8,
        area9: 0.9,
      },
      {
        name: 2005,
        area1: 3.7,
        area2: 2.7,
        area3: 6.1,
        area4: 2.1,
        area5: 1.1,
        area6: 1.2,
        area7: 5.9,
        area8: 5.9,
        area9: 0.9,
      },
      {
        name: 2006,
        area1: 3.7,
        area2: 2.8,
        area3: 6.1,
        area4: 2.1,
        area5: 1.1,
        area6: 1.3,
        area7: 6.5,
        area8: 6.1,
        area9: 1,
      },
      {
        name: 2007,
        area1: 3.7,
        area2: 2.8,
        area3: 6.1,
        area4: 2.2,
        area5: 1.1,
        area6: 1.4,
        area7: 7,
        area8: 6.2,
        area9: 1.1,
      },
      {
        name: 2008,
        area1: 3.6,
        area2: 2.8,
        area3: 5.9,
        area4: 2.3,
        area5: 1.2,
        area6: 1.5,
        area7: 7.5,
        area8: 6.3,
        area9: 1.1,
      },
      {
        name: 2009,
        area1: 3.3,
        area2: 2.5,
        area3: 5.5,
        area4: 2.2,
        area5: 1.2,
        area6: 1.6,
        area7: 7.9,
        area8: 6.4,
        area9: 1,
      },
      {
        name: 2010,
        area1: 3.4,
        area2: 2.7,
        area3: 5.7,
        area4: 2.3,
        area5: 1.2,
        area6: 1.7,
        area7: 8.6,
        area8: 6.7,
        area9: 1.1,
      },
      {
        name: 2011,
        area1: 3.3,
        area2: 2.7,
        area3: 5.5,
        area4: 2.3,
        area5: 1.3,
        area6: 1.8,
        area7: 9.5,
        area8: 6.9,
        area9: 1.1,
      },
      {
        name: 2012,
        area1: 3.3,
        area2: 2.7,
        area3: 5.3,
        area4: 2.4,
        area5: 1.3,
        area6: 2,
        area7: 9.8,
        area8: 7.2,
        area9: 1.1,
      },
      {
        name: 2013,
        area1: 3.2,
        area2: 2.6,
        area3: 5.5,
        area4: 2.5,
        area5: 1.3,
        area6: 2,
        area7: 10,
        area8: 7.2,
        area9: 1.1,
      },
      {
        name: 2014,
        area1: 3,
        area2: 2.6,
        area3: 5.5,
        area4: 2.5,
        area5: 1.4,
        area6: 2.2,
        area7: 10,
        area8: 7.3,
        area9: 1.1,
      },
      {
        name: 2015,
        area1: 3.1,
        area2: 2.5,
        area3: 5.4,
        area4: 2.4,
        area5: 1.3,
        area6: 2.3,
        area7: 9.8,
        area8: 7.5,
        area9: 1.2,
      },
      {
        name: 2016,
        area1: 3.1,
        area2: 2.5,
        area3: 5.2,
        area4: 2.4,
        area5: 1.4,
        area6: 2.4,
        area7: 9.7,
        area8: 7.6,
        area9: 1.2,
      },
      {
        name: 2017,
        area1: 3.1,
        area2: 2.5,
        area3: 5.2,
        area4: 2.4,
        area5: 1.4,
        area6: 2.4,
        area7: 9.9,
        area8: 7.8,
        area9: 1.2,
      },
      {
        name: 2018,
        area1: 3,
        area2: 2.5,
        area3: 5.4,
        area4: 2.3,
        area5: 1.4,
        area6: 2.6,
        area7: 10.3,
        area8: 7.8,
        area9: 1.3,
      },
      {
        name: 2019,
        area1: 2.9,
        area2: 2.5,
        area3: 5.3,
        area4: 2.3,
        area5: 1.4,
        area6: 2.6,
        area7: 10.5,
        area8: 8,
        area9: 1.3,
      },
      {
        name: 2020,
        area1: 2.6,
        area2: 2.3,
        area3: 4.7,
        area4: 2.1,
        area5: 1.3,
        area6: 2.4,
        area7: 10.7,
        area8: 7.7,
        area9: 1,
      },
    ],
  },
  {
    name: "kuvio-3.9",
    height: 500,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "%",
    yDomain: [0, 40],
    xDomain: ["dataMin", "dataMax"],
    yAxisOffset: 0,
    xAxisOffset: 0,
    minTickGapX: 15,
    xTickCount: 8,
    yTickCount: 8,
    xAxisHeight: 50,
    yAxisWidth: 50,
    margins: [0, 15, 15, 5],
    tickDx: 0,
    type: ["number", "number"], // [xAxis, yAxis]
    manualTicksX: [
      "1990",
      "1994",
      "1998",
      "2002",
      "2006",
      "2010",
      "2014",
      "2016",
    ],
    names: [
      "G01 Yleinen julkishallinto",
      "G02 Puolustus",
      "G03 Yleinen järjestys ja turvallisuus",
      "G04 Elinkeinoelämän edistäminen",
      "G05 Ympäristönsuojelu",
      "G06 Asuminen ja yhdyskunnat",
      "G07 Terveydenhuolto",
      "G08 Vapaa-aika, kulttuuri ja uskonto",
      "G09 Koulutus",
      "G10 Sosiaaliturva",
    ],
    colorPicker: [
      "#1a1c1a",
      "#e0f000",
      "#575e57",
      "#0a3309",
      "#206e20",
      "#64e864",
      "#0025e0",
      "#1c916e",
      "#4ddb4b",
      "#ffe203",
      "#46635e",
    ],
    data: [
      {
        name: 1990,
        area1: 5.6,
        area2: 1.5,
        area3: 1.3,
        area4: 6.4,
        area5: 0.2,
        area6: 0.7,
        area7: 5.9,
        area8: 1.4,
        area9: 6.2,
        area10: 18.6,
      },
      {
        name: 1992,
        area1: 7.2,
        area2: 2.1,
        area3: 1.5,
        area4: 7.5,
        area5: 0.3,
        area6: 0.8,
        area7: 6.8,
        area8: 1.5,
        area9: 7.4,
        area10: 26.7,
      },
      {
        name: 1994,
        area1: 8.3,
        area2: 2,
        area3: 1.4,
        area4: 8.5,
        area5: 0.3,
        area6: 0.6,
        area7: 6.2,
        area8: 1.2,
        area9: 7,
        area10: 27,
      },
      {
        name: 1996,
        area1: 8.9,
        area2: 1.9,
        area3: 1.4,
        area4: 6.8,
        area5: 0.3,
        area6: 0.8,
        area7: 6.4,
        area8: 1.3,
        area9: 6.8,
        area10: 24.9,
      },
      {
        name: 1998,
        area1: 8.3,
        area2: 1.6,
        area3: 1.3,
        area4: 5.4,
        area5: 0.3,
        area6: 0.5,
        area7: 5.8,
        area8: 1.2,
        area9: 6.1,
        area10: 21.6,
      },
      {
        name: 2000,
        area1: 7.5,
        area2: 1.4,
        area3: 1.3,
        area4: 4.8,
        area5: 0.3,
        area6: 0.4,
        area7: 5.7,
        area8: 1.1,
        area9: 5.9,
        area10: 19.5,
      },
      {
        name: 2002,
        area1: 7,
        area2: 1.3,
        area3: 1.2,
        area4: 4.9,
        area5: 0.3,
        area6: 0.4,
        area7: 6.2,
        area8: 1.1,
        area9: 6.1,
        area10: 19.9,
      },
      {
        name: 2004,
        area1: 7,
        area2: 1.5,
        area3: 1.3,
        area4: 4.7,
        area5: 0.3,
        area6: 0.3,
        area7: 6.5,
        area8: 1.1,
        area9: 6.2,
        area10: 20.3,
      },
      {
        name: 2006,
        area1: 6.9,
        area2: 1.4,
        area3: 1.2,
        area4: 4.7,
        area5: 0.3,
        area6: 0.3,
        area7: 6.7,
        area8: 1,
        area9: 6,
        area10: 19.6,
      },
      {
        name: 2008,
        area1: 7,
        area2: 1.4,
        area3: 1.2,
        area4: 4.6,
        area5: 0.3,
        area6: 0.4,
        area7: 6.7,
        area8: 1.1,
        area9: 5.8,
        area10: 19.3,
      },
      {
        name: 2010,
        area1: 7.7,
        area2: 1.5,
        area3: 1.5,
        area4: 4.9,
        area5: 0.3,
        area6: 0.5,
        area7: 7.3,
        area8: 1.2,
        area9: 6.5,
        area10: 22.6,
      },
      {
        name: 2012,
        area1: 8.2,
        area2: 1.5,
        area3: 1.4,
        area4: 4.9,
        area5: 0.2,
        area6: 0.4,
        area7: 7.7,
        area8: 1.2,
        area9: 6.4,
        area10: 23.6,
      },
      {
        name: 2014,
        area1: 8.3,
        area2: 1.4,
        area3: 1.3,
        area4: 4.9,
        area5: 0.3,
        area6: 0.4,
        area7: 7.8,
        area8: 1.4,
        area9: 6.3,
        area10: 25.2,
      },
      {
        name: 2016,
        area1: 7.9,
        area2: 1.3,
        area3: 1.2,
        area4: 4.5,
        area5: 0.2,
        area6: 0.3,
        area7: 7.2,
        area8: 1.4,
        area9: 6,
        area10: 25.5,
      },
      {
        name: 2017,
        area1: 7.8,
        area2: 1.2,
        area3: 1.1,
        area: 4.3,
        area5: 0.2,
        area6: 0.3,
        area7: 7,
        area8: 1.5,
        area9: 5.7,
        area10: 24.5,
      },
      {
        name: 2018,
        area1: 8,
        area2: 1.2,
        area3: 1.1,
        area4: 4.2,
        area5: 0.2,
        area6: 0.3,
        area7: 7.1,
        area8: 1.5,
        area9: 5.5,
        area10: 24.3,
      },
      {
        name: 2019,
        area1: 8,
        area2: 1.2,
        area3: 1.2,
        area4: 4.2,
        area5: 0.2,
        area6: 0.3,
        area7: 7.1,
        area8: 1.5,
        area9: 5.6,
        area10: 24.1,
      },
    ],
  },
]
