import { Link } from "gatsby"
import { CourseStatusProvider } from "moocfi-quizzes"
import React from "react"
import styled, { keyframes } from "styled-components"
import courseSettings from "../../../course-settings"
import LoginStateContext, {
  withLoginStateContext,
} from "../../contexes/LoginStateContext"
import thumbnail01 from "../../images/business_cycle.png"
import thumbnail02 from "../../images/economic_growth.png"
import thumbnail03 from "../../images/externalities_public_finance.png"
import thumbnail07 from "../../images/global_challenges.png"
import thumbnail06 from "../../images/markets_and_price_discovery.png"
import thumbnail04 from "../../images/savings.png"
import thumbnail05 from "../../images/understanding_the_economy.png"
import { accessToken } from "../../services/moocfi"
import { respond } from "../../_respond"
import ExerciseVisualization from "../CourseProgress/ExerciseVisualization"

// for svg exports as a component

const courses = [
  {
    icon: thumbnail05,
    num: "1",
    title: "Talouden maailma",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
    url: "/part-1",
    color: "#268B74",
    background: "#E8F2F0",
    border: "#D2E7E2",
    iconExplanation: "Jääkiekkomaila",
  },
  {
    icon: thumbnail06,
    num: "2",
    title: "Markkinat ja hinnanmuodostus",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
    url: "/part-2",
    color: "#F3BC30",
    background: "#FDF7E9",
    border: "#FAEFD2",
    iconExplanation: "Kysyntä ja tarjonta -graafi",
  },
  {
    icon: thumbnail03,
    num: "3",
    title: "Ulkoisvaikutukset ja julkistalous",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
    url: "/part-3",
    color: "#2D4CAD",
    background: "#F1F6FC",
    border: "#D8E6F7",
    iconExplanation: "Piirakka-kuvaaja",
  },
  {
    icon: thumbnail04,
    num: "4",
    title: "Rahoitusmarkkinat ja investoiminen",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
    url: "/part-4",
    color: "#7C73D9",
    background: "#F1F0FA",
    border: "#DDDBF5",
    iconExplanation: "Graafeja ja säästämistä",
  },
  {
    icon: thumbnail02,
    num: "5",
    title: "Talouskasvu",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
    url: "/part-5",
    color: "#4E84B2",
    background: "#F1F3F4",
    border: "#D8E6F7",
    iconExplanation: "Puissa kasvaa rahaa",
  },
  {
    icon: thumbnail01,
    num: "6",
    title: "Suhdannevaihtelut",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
    url: "/part-6",
    color: "#50E3C2",
    background: "#F0FEFB",
    border: "#D6FCF4",
    iconExplanation: "Itsensä ympäri menevä nuoli",
  },
  {
    icon: thumbnail07,
    num: "7",
    title: "Globaalit haasteet",
    text: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy. Lorem ipsum dolor sit amet, consetetur sadipscing elitr...",
    url: "/part-7",
    color: "#4C76C9",
    background: "#F1F3F4",
    border: "#DAE3F4",
    iconExplanation: "Sateinen sää.",
  },
]

const StyledLink = styled(Link)`
  color: #333;

  &:hover {
    text-decoration: none;
    color: #333;
  }
`

const CourseGridWrapper = styled.div`
  background: #fff;
  width: auto;
  height: 36rem;
  border-radius: 5px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${respond.mobile`
  border-radius: 0px;
  `}

  &:hover {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  }
`

const CourseGridSVG = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60%;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  ${respond.mobile`
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  `}

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    margin-bottom: 0;
    overflow: hidden;
    transition: transform 0.5s ease;
  }
`

const CourseGridTextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 25px 0 25px;
  height: 40%;
  top: 20px;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;

  ${respond.mobile`
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  `}

  div {
    text-align: center;
  }

  h2 {
    font-family: "Josefin Sans", sans-serif;
    font-size: 26px;
    font-weight: 400;
    z-index: 20;
    margin-bottom: 0.6rem;
    margin-top: 1.5rem;
    text-transform: uppercase;
  }

  .content {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    font-family: Montserrat;
    padding-top: 1rem;
  }

  .content &:hover {
    animation: ${bounceAmination} 2s infinite;
  }

  h6 {
    line-height: 0;
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
    font-size: 1rem;
    opacity: 0.5;
  }
  span {
    color: #202020;
    font-size: 1rem;
    line-height: 1.4;
    opacity: 0.8;
    z-index: 20;
    wdith: 50%;
    padding: 0 1rem;
  }
`

const bounceAmination = keyframes`
0%, 20%, 50%, 80%, 100% {transform: translateX(0);}
40% {transform: translateX(-30px);}
60% {transform: translateX(-15px);}
`

const CourseGrid = ({ parts }) => {
  const { loggedIn } = React.useContext(LoginStateContext)
  const courseLength = courses.length9db228fcfb39afabc983aa94bfdd0a05c582
  return (
    <CourseStatusProvider
      accessToken={accessToken()}
      courseId={courseSettings.courseId}
      languageId={courseSettings.language}
    >
      <>
        {courses.map((course, index) => (
          <StyledLink
            key={index}
            to={parts[index].path}
            aria-label={course.title}
            role="link"
          >
            <CourseGridWrapper>
              <CourseGridSVG style={{ background: `${course.color}` }}>
                {" "}
                <img
                  src={course.icon}
                  width="500px"
                  alt={course.iconExplanation}
                ></img>
              </CourseGridSVG>
              <CourseGridTextBox
                style={{
                  background: `${course.background}`,
                  border: `1.8px solid ${course.border}`,
                  borderTop: "none",
                }}
              >
                <div className="content" style={{ margin: "0 0 10px 0" }}>
                  <h6>LUKU {course.num}</h6>
                  <h2>{parts[index].topic}</h2>
                  <span>{parts[index].subTitle}</span>
                </div>
                {loggedIn && (
                  <>
                    <ExerciseVisualization
                      color={course.color}
                      topic={Number(course.num)}
                    />
                  </>
                )}
              </CourseGridTextBox>
            </CourseGridWrapper>
          </StyledLink>
        ))}
      </>
    </CourseStatusProvider>
  )
}

export default withLoginStateContext(CourseGrid)
