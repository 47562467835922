import React from "react"
import styled from "styled-components"
import H3 from "../Headers/H3"

//TO-DO: Make Grid responsive without Media queries
//TO-DO: + More Breakpoints

const Wrapper = styled.div`
  margin: 8rem 0;
  text-align: center;
`

const StyledLink = styled.a`
  color: #53ceaa;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  position: relative;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  line-height: 1.5rem;
  margin: 0.2rem 1.5rem;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #53ceaa;
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  :hover {
    text-decoration: none;
    color: #53ceaa;
  }
`

export default (props) => {
  return (
    <Wrapper>
      <H3>Haluatko oppia enemmän taloudesta?</H3>
      <StyledLink href="https://www.core-econ.org/the-economy/fi/">
        Lue CORE-työryhmän oppikirja <i>Talous</i>
      </StyledLink>
    </Wrapper>
  )
}
