import React, { useState } from "react"
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import styled from "styled-components"
import Caption from "../Caption"
import CustomTooltip from "./CustomTooltip"
import { data } from "./data/LineChartData"

const Chart = (props) => {
  const defaultColorPicker = [
    "#0264db",
    "#ff8400",
    "#eb34c0",
    "#6f00ff",
    "#f95d6a",
    "#00ff0d",
    "#2f4b7c",
    "#ffd900",
    "#ff7300",
    "#444d46",
    "#46635e",
  ]
  const chartName = props.chart
  const chart = data.find((object) => object.name === chartName)

  const colorPicker =
    chart?.colorPicker !== undefined ? chart?.colorPicker : defaultColorPicker
  let tempOriginal = [...colorPicker]

  if (chart?.chooseReference !== undefined) {
    tempOriginal[chart.chooseReference - 1] = "#7d7d7d"
  }
  if (chart?.chooseReference2 !== undefined) {
    tempOriginal[chart.chooseReference2 - 1] = "#000000"
  }
  const currentColor =
    chart !== undefined ? chart.names.map((names) => true) : []

  const [current, setCurrent] = useState(currentColor)
  const [original, setOriginal] = useState(tempOriginal)
  const [colorsOn, setColorsOn] = useState(currentColor.length)

  if (chart === undefined) {
    console.log("line-chart: Chart not found")
    return (
      <div style={{ color: "red", margin: "auto", width: "30%" }}>
        Kuvaajaa ei löytynyt{" "}
      </div>
    )
  }

  const handleClick = (e, clickedIndex) => {
    if (chart.names.length < 4) {
      return
    }
    var colors = [...current]
    let color = colors[clickedIndex]
    if (colorsOn === chart.names.length) {
      colors = colors.map((colorItem, index) => {
        return index === clickedIndex
      })
      setColorsOn(1)
      setCurrent(colors)
      return
    }
    color = current[clickedIndex] ? false : true
    colors[clickedIndex] = color
    const colorsOnLogic = color === true ? colorsOn + 1 : colorsOn - 1

    if (colorsOnLogic === 0) {
      colors = chart.names.map((names) => true)
      setColorsOn(chart.names.length)
      setCurrent(colors)
      return
    }
    setColorsOn(colorsOnLogic)
    setCurrent(colors)
  }

  const Heading = styled.h5`
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 25px;
    text-align: center;
    align-self: center;
    color: #333;
  `
  const labelSideways = chart.yAxisLabel.length > 1 ? -90 : 0

  const Y2labelSideways = chart.yAxisLabel.length > 1 ? 90 : 0

  let xAxisPadding =
    chart.xAxisPadding !== undefined ? `${chart.xAxisPadding}px` : "20px"

  let yAxisPadding =
    chart.yAxisPadding !== undefined ? `${chart.yAxisPadding}px` : "20px"

  const xAxisOffset = chart.xAxisOffset === undefined ? -10 : chart.xAxisOffset

  const yAxisOffset = chart.yAxisOffset === undefined ? -10 : chart.yAxisOffset

  const chooseColor = (index) => {
    let colorOnOff = current[index]
    return colorOnOff ? original[index] : "rgba(0,0,0,0)"
  }

  const chooseDot = (index) => {
    return current[index] ? { r: 5 } : false
  }

  const chartHeight = chart.height ? chart.height : 400
  const chartWidth = chart.width ? `${chart.width}` : "100%"

  const source = chart.source

  const whichYId =
    chart.whichYId === undefined
      ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      : chart.whichYId

  const manualTicksX =
    chart.manualTicksX === undefined ? null : chart.manualTicksX

  const manualTicksY =
    chart.manualTicksY === undefined ? null : chart.manualTicksY

  const manualTicksY2 =
    chart.manualTicksY2 !== undefined ? chart.manualTicksY2 : null

  const xDomain = chart.xDomain === undefined ? ["auto", "auto"] : chart.xDomain

  const xDomain2 =
    chart.xDomain === undefined ? ["auto", "auto"] : chart.xDomain2

  const yDomain = chart.yDomain !== undefined ? chart.yDomain : ["auto", "auto"]

  const yDomain2 =
    chart.yDomain2 !== undefined ? chart.yDomain2 : ["auto", "auto"]

  const scale = chart.scale !== undefined ? chart.scale : "auto"

  const type = chart.type !== undefined ? chart.type : ["category", "number"]

  const yTickCount = chart.yTickCount !== undefined ? chart.yTickCount : 5

  const xTickCount = chart.xTickCount !== undefined ? chart.xTickCount : 5

  const yFontSize = chart.yFontSize !== undefined ? chart.yFontSize : "1.125rem"

  const xLabelPosition =
    chart.xLabelPosition !== undefined
      ? chart.xLabelPosition
      : "insideBottomRight"

  const xTickFontSize =
    chart.xTickFontSize !== undefined ? chart.xTickFontSize : "1.125rem"

  const connectNulls =
    chart.connectNulls !== undefined ? chart.connectNulls : false

  const dot = chart.dot !== undefined ? chart.dot : false

  const xAxisLabelSideways =
    chart.xAxisLabelSideways !== undefined ? chart.xAxisLabelSideways : 0

  const minTickGapX = chart.minTickGapX !== undefined ? chart.minTickGapX : 5

  const minTickGapY = chart.minTickGapY !== undefined ? chart.minTickGapY : 5

  const fill = chart.fill !== undefined ? chart.fill : undefined

  const chooseDashReference =
    chart.chooseDashReference !== undefined ? chart.chooseDashReference : null

  const rightYAxis = chart.rightYAxis !== undefined ? chart.rightYAxis : false

  const linesThatBelongToRightYAxis =
    chart.yAxisId2 !== undefined ? chart.yAxisId2 : null

  const tickDy = chart.tickDy !== undefined ? chart.tickDy : 0

  const tickDx = chart.tickDx !== undefined ? chart.tickDx : 0

  const referenceLineLabel =
    chart.referenceLineLabel !== undefined ? chart.referenceLineLabel : ""

  const chartMargin =
    chart.margins !== undefined ? chart.margins : [5, 30, 20, 5]

  const yInterval =
    chart.yInterval !== undefined ? chart.yInterval : "preserveEnd"

  const xInterval =
    chart.xInterval !== undefined ? chart.xInterval : "preserveEnd"

  const dashReference = (index) => {
    if (index === chooseDashReference) {
      return "5 5"
    } else {
      return null
    }
  }

  const xAxisHeight = chart.xAxisHeight !== undefined ? chart.xAxisHeight : 30

  const yAxisWidth = chart.yAxisWidth !== undefined ? chart.yAxisWidth : 30

  const y2AxisWidth = chart.y2AxisWidth !== undefined ? chart.y2AxisWidth : 30

  return (
    <div>
      <div>
        {chart.header !== undefined ? (
          <Heading> {chart.header} </Heading>
        ) : null}
      </div>
      <div
        style={{
          width: chartWidth,
          height: chartHeight,
          margin: "auto",
        }}
      >
        <ResponsiveContainer key={chart}>
          <LineChart
            key={chart}
            data={chart.data}
            margin={{
              top: chartMargin[0],
              right: chartMargin[1],
              left: chartMargin[2],
              bottom: chartMargin[3],
            }}
          >
            <CartesianGrid strokeDasharray="3 3"> </CartesianGrid>
            <XAxis
              height={xAxisHeight}
              angle={xAxisLabelSideways}
              dataKey="name"
              xAxisId="0"
              type={type[0]}
              domain={xDomain}
              minTickGap={minTickGapX}
              interval={xInterval}
              tickCount={xTickCount}
              ticks={manualTicksX}
              tick={{ dy: tickDy, dx: tickDx, fontSize: xTickFontSize }}
            >
              {" "}
              <Label
                value={chart.xAxisLabel}
                position={xLabelPosition}
                offset={xAxisOffset}
              ></Label>{" "}
            </XAxis>
            <YAxis
              width={yAxisWidth}
              yAxisId="0"
              ticks={manualTicksY}
              domain={yDomain}
              interval={yInterval}
              type={type[1]}
              tickCount={yTickCount}
              scale={scale}
            >
              {" "}
              <Label
                value={chart.yAxisLabel}
                position="insideLeft"
                angle={labelSideways}
                dy={chart.yAxisOffset}
                fontSize={yFontSize}
              ></Label>{" "}
            </YAxis>
            {rightYAxis === true ? (
              <YAxis
                yAxisId="1"
                orientation="right"
                width={y2AxisWidth}
                ticks={manualTicksY2}
                interval={yInterval}
                domain={yDomain2}
                type={type[1]}
                tickCount={yTickCount}
                scale={scale}
              >
                {" "}
                <Label
                  value={chart.yAxisLabel2}
                  position="right"
                  angle={Y2labelSideways}
                  dy={chart.yAxisOffset2}
                  fontSize={yFontSize}
                ></Label>{" "}
              </YAxis>
            ) : null}

            <Tooltip
              content={<CustomTooltip current={current}></CustomTooltip>}
            />
            <Legend
              onClick={handleClick}
              display="block"
              wrapperStyle={{
                paddingTop: `${xAxisPadding}`,
              }}
              payload={chart.names.map((name, index) => ({
                value: name,
                type: "line",
                id: index,
                color: chooseColor(index),
              }))}
            />
            {chart.xReference !== undefined ? (
              <ReferenceLine
                x={chart.xReference}
                yAxisId="0"
                xAxisId="0"
                stroke="black"
                label={{
                  position: "top",
                  value: referenceLineLabel,
                  fill: "red",
                  fontSize: 14,
                }}
                strokeDasharray="7 7"
              />
            ) : null}
            {chart.yReference !== undefined ? (
              <ReferenceLine
                y={chart.yReference}
                stroke="black"
                yAxisId="0"
                xAxisId="0"
                label=""
                strokeDasharray="7 7"
              />
            ) : null}
            {chart.referenceArea !== undefined ? (
              <ReferenceArea
                yAxisId="0"
                xAxisId="0"
                x1={chart.referenceArea[0]}
                x2={chart.referenceArea[1]}
                y1={chart.referenceArea[2]}
                y2={chart.referenceArea[3]}
                stroke="green"
                strokeOpacity={0.5}
              />
            ) : null}
            {chart.names.map((chartLine, index) => (
              <Line
                strokeDasharray={dashReference(index + 1)}
                connectNulls={connectNulls}
                key={index}
                yAxisId={`${whichYId[index]}`}
                xAxisId="0"
                inactive={true}
                type="monotone"
                hide={current[index] === false}
                dataKey={`line${index + 1}`}
                stroke={chooseColor(index)}
                name={chartLine}
                fill={fill}
                activeDot={chooseDot(index)}
                dot={dot}
                strokeWidth={2}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>
      {source !== undefined ? <Caption>{source} </Caption> : null}
    </div>
  )
}

export default Chart
