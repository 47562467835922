import React from "react"
import styled, { keyframes } from "styled-components"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"
import { respond } from "../_respond"

const openAnimation = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`

const slideDown = keyframes`
from { opacity: 0; height: 0; padding: 0;}
to { opacity: 1; height: 100%; padding: 10px;}
`

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  details[open] summary ~ * {
    animation: ${openAnimation} 0.3s ease-in-out;
    color: #333;
  }

  details[open] > div {
    animation-name: ${slideDown};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  details summary::-webkit-details-marker {
    display: none;
  }

  details[open] > summary {
    color: #1c1c1c;
  }

  details summary {
    padding: 1rem 0;
    border-top: 2px solid #333;
    position: relative;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: medium;
    list-style: none;
    color: #333;
  }

  details summary:after {
    content: "+";
    color: ${(props) => (props.textcolor === "light" ? "white" : "black")};
    position: absolute;
    font-size: 1.75rem;
    line-height: 0;
    margin-top: 0.75rem;
    right: 0;
    font-weight: 200;
    transform-origin: center;
    transition: all 200ms linear;
  }
  details[open] summary:after {
    transform: rotate(45deg);
    font-size: 2rem;
  }
  details[open] summary {
    font-weight: 600;
    opacity: 0.9;
  }
  details summary {
    outline: 0;
  }

  details ul {
    margin: 0 0 1.8rem 1.5rem;
  }

  details ul li {
    font-size: 1.1rem;
    font-family: "Lato", sans-serif;
    margin: 0 0 0.2rem;
    padding-left: 8px;
    list-style-position: outside;
  }

  ul li::marker {
    content: "⦾";
    text-align: center;
    margin-left: 2rem !important;
  }

  ${respond.mobile`
  grid-template-columns: 1fr;
  `}

  ${respond.xs`
  grid-template-columns: 1fr;
`}

  ${respond.sm`
`}

${respond.md`
`}

${respond.lg`
`}
`

const DetailTag = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default withSimpleErrorBoundary(DetailTag)
