import React from "react"
import styled from "styled-components"
import { respond } from "../../_respond"

const HeroSection = styled.div`
  padding: 6rem 12vw;
  width: 75%;
  display: grid;
  grid-template-columns: 1fr;
  background: #f9f9f9;
  margin: 0 auto;

  ${respond.mobile`
  padding: 2rem 2rem;
  width: 100%;
  margin: 0;
`}

  ${respond.xs`
  padding: 4rem 2rem;
`}

  ${respond.sm`
/*   padding: 4rem 12vw; */
`}

${respond.md`
/* padding: 6rem 12vw; */
`}

${respond.lg`
padding: 6rem 12vw;
`}
`

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 3rem;

  h1 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
    font-size: clamp(24px, 3.2vw, 40px);
    line-height: 1.4;
    color: #333;

    span {
      font-family: "Josefin Sans", sans-serif;
      font-weight: 500;
      font-size: clamp(26px, 3.2vw, 40px);
      line-height: 1.5;
      color: #27967c;
    }
  }

  span {
    font-family: Lato;
    font-size: 1.2rem;
    line-height: 1.4;
    color: #202020;
  }

  ${respond.mobile`
  padding: 0;
  padding: 2rem 0;
`}

  ${respond.xs`
  width: 100%;
  margin-bottom: 1rem;

`}

  ${respond.md`
  margin-bottom: 1rem;

`}

  ${respond.lg`
  width: 100%;
  margin-bottom: 2rem;
`}
`
const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
`

const ResponsiveIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
`
export default (props) => {
  return (
    <HeroSection>
      <HeroText>
        <h1>
          <span>Verkkokurssi</span> siitä miten leipä löytää pöytäämme,
          varallisuus maailmassa jakautuu ja maailman suuret ongelmat syntyvät
          ja voidaan myös ratkaista.
        </h1>
      </HeroText>
      <VideoWrapper>
        <ResponsiveIframe
          src="https://player.vimeo.com/video/519548808"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></ResponsiveIframe>
      </VideoWrapper>
    </HeroSection>
  )
}
