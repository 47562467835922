import React from "react"
import styled from "styled-components"
import { normalizeExerciseId } from "../../util/strings"

const Heading = styled.div`
  & h4 {
    font-family: "Montserrat", sans-serif;
    font-size: clamp(18px, 2vw, 24px);
    font-weight: 600;
    color: #333;
  }
`

const H4 = ({ children }) => {
  let text = "unknown heading"
  text = typeof children === "string" ? children : "unknown heading"
  const id = `heading-${normalizeExerciseId(text)}`
  return (
    <Heading>
      <h4 className="material-header" id={id}>
        {" "}
        {children}{" "}
      </h4>{" "}
    </Heading>
  )
}

export default H4
