import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  background: #4fe2c1;
  width: 100%;
  height: 20px;
`

export default ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}
