import { Snackbar } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { useClickAway } from "react-use"
import styled from "styled-components"
import { ExternalLink } from "../../components/Buttons"
import Form from "../../components/Form"
import { Primary } from "../../components/LocalButton"
import { userDetails } from "../../services/moocfi"

// Form commented out because the file is not in github
// import Form from "../../components/Form"

const Title = styled.h2`
  margin-bottom: 0.4rem;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: clamp(40px, 4vw, 60px);
  line-height: 1;
  color: #333;
  text-transform: none;
`

const CompletionContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  text-transform: none;
  margin-bottom: 4rem;
  position: relative;
`

const Subtitle = styled.p`
  margin: 0rem auto;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-family: "Josefin Sans", sans-serif;
`
const SmallText = styled.p`
  margin: 1rem auto;
  max-width: 240px;
  line-height: 1.4;
  opacity: 0.8;
  font-size: 0.8rem;
`

const Info = styled.p`
  margin: 0rem auto;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  line-height: 1.6;
  font-family: "Josefin Sans", sans-serif;
`

const RegisterCredit = styled.div`
  margin: 3rem auto;
  max-width: 650px;

  a {
    margin-right: 10px;
  }
`

const PositionDiv = styled.div`
  position: absolute;
  top: -80px;
  left: 35%;
  max-width: 600px;
`

const SuccessSnackbarContent = styled.div`
  padding: 2rem;
  background: #52e3c2;
  color: #333;
`

const Completed = () => {
  const [showForm, setshowForm] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [user, setUser] = useState(null)

  const formRef = useRef(null)

  const getUserDetails = async () => {
    const userData = await userDetails().then((value) => {
      setUser(value)
    })
  }

  useEffect(() => {
    getUserDetails()
  }, [])

  useClickAway(formRef, () => {
    setshowForm(false)
  })

  const handleClick = (e) => {
    e.preventDefault()
    setshowForm(true)
  }
  if (user === null) {
    return <div></div>
  }

  let organizationName = null
  if (
    user.extra_fields.course_variant !== null &&
    user.extra_fields.course_variant !== ""
  ) {
    organizationName = user.extra_fields.course_variant
  } else {
    organizationName = "Aalto"
  }

  return (
    <CompletionContainer>
      <Title>Onneksi olkoon!</Title>
      <Subtitle>kurssin läpäisemistä.</Subtitle>
      {user &&
      user.extra_fields.course_variant !== null &&
      user.extra_fields.course_variant !== "" ? (
        <>
          <RegisterCredit>
            <Info>
              Voit nyt kirjata suoritusmerkinnän kurssista seuraavalla
              lomakkeella.
            </Info>
            <ExternalLink onClick={handleClick} bg="#33BF9F" color="#333">
              {user.extra_fields.course_variant === "Laurea" ? (
                <span>Laurea-ammattikorkeakoulu</span>
              ) : (
                <span>Hämeen ammattikorkeakoulu</span>
              )}
            </ExternalLink>
          </RegisterCredit>
        </>
      ) : (
        <>
          {/* <a href="https://www.mooc.fi/fi/profile/completions">
            <Primary bg="#333">Lataa sertifikaatti</Primary>
          </a>
          <SmallText>
            Voit ladata epävirallisen sertifikaatin tästä napista
          </SmallText> */}
          <RegisterCredit>
            <Info>
              Jos sinulla on suomalainen henkilötunnus, suosittelemme sinua
              ottamaan ilmaiset opintopisteet seuraavalla lomakkeella
            </Info>
            <ExternalLink onClick={handleClick} bg="#33BF9F" color="#333">
              Aalto yliopisto
            </ExternalLink>
            <ExternalLink
              href="https://www.mooc.fi/fi/profile/completions"
              bg="#EAEAEA"
              color="#33BF9F"
            >
              Helsingin yliopisto
            </ExternalLink>
          </RegisterCredit>
        </>
      )}
      {showForm && (
        <PositionDiv ref={formRef}>
          <Form
            organizationName={organizationName}
            onSuccess={() => {
              setShowSuccess(true)
            }}
          />
        </PositionDiv>
      )}
      {showSuccess && (
        <Snackbar
          open={true}
          autoHideDuration={60000}
          onClose={() => {
            setShowSuccess(false)
          }}
        >
          <SuccessSnackbarContent>
            Tiedot lähetetty onnistuneesti.
          </SuccessSnackbarContent>
        </Snackbar>
      )}
    </CompletionContainer>
  )
}

export default Completed
