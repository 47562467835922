import React from "react"
import styled from "styled-components"
import { respond } from "../../_respond"

const HeaderWrapper = styled.div`
  padding: 0 5rem;
  height: 58vh;
  background: ${(props) => props.color};
  position: relative;
  margin-bottom: 2rem;
  display: grid;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${respond.mobile`
  height: 50vh;
  padding: 0;
`}

  svg {
    ${respond.mobile`
    width: 90vw;
  `}
  }

  p {
    text-align: center;
    color: #3b4754;
    font-size: 1rem;
    font-weight: 400;
  }

  H2 {
    text-align: center;
    font-weight: 600;
    opacity: 0.8;
    color: ${(props) => props.textcolor};
  }
`

const StyledThumbnail = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`

export default (props) => {
  return (
    <>
      <HeaderWrapper color={props.color} textcolor={props.textcolor}>
        <div>
          <StyledThumbnail>
            <props.blob width="34vw" height="auto" />
          </StyledThumbnail>
        </div>
      </HeaderWrapper>
    </>
  )
}
