import React, { PureComponent } from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { data } from "./data/BarChartData"

export default class BarChartComp extends PureComponent {
  constructor(props) {
    super()

    const defaultColorPicker = [
      "#82ca9d",
      "#ff1900",
      "#eb34c0",
      "#6f00ff",
      "#f95d6a",
      "#00ff0d",
      "#2f4b7c",
      "#ffd900",
      "#ff7300",
      "#444d46",
      "#46635e",
    ]

    const chartName = props.chart

    const chart = data.find((object) => object.name === chartName)

    if (chart === undefined) {
      console.log("bar-chart: Chart not found")
      return null
    }

    const colorPicker =
      chart.colorPicker !== undefined ? chart.colorPicker : defaultColorPicker
    let original = [...colorPicker]
    const currentColor = chart.data.map((names) => true)

    this.state = {
      chart: chart,
      current: currentColor,
      original: original,
      colorsOn: currentColor.length,
    }
  }

  render() {
    if (this.state === null) {
      console.log("bar-chart: Chart not found")
      return (
        <div style={{ color: "red", margin: "auto", width: "30%" }}>
          Kuvaajaa ei löytynyt{" "}
        </div>
      )
    }

    const chart = this.state.chart

    const chooseColor = (index) => {
      let colorOnOff = this.state.current[index]
      return colorOnOff ? this.state.original[index] : "rgba(0,0,0,0)"
    }

    const chartHeight = chart.height ? chart.height : 400
    const chartWidth = chart.width ? `${chart.width}` : "100%"

    const chart2 = this.props.source !== undefined ? true : false

    var yDomain
    var manualTicksY
    var manualTicksX

    if (chart2 === true) {
      if (chart.yDomain2 === undefined) {
        yDomain = ["auto", "auto"]
      } else {
        yDomain = chart.yDomain2
      }
    } else {
      if (chart.yDomain === undefined) {
        yDomain = ["auto", "auto"]
      } else {
        yDomain = chart.yDomain
      }
    }

    if (chart.manualTicksY !== undefined) {
      if (chart2 === false) {
        manualTicksY = chart.manualTicksY
      } else {
        manualTicksY = chart.manualTicksY2
      }
    } else {
      manualTicksY = null
    }

    if (chart.manualTicksX !== undefined) {
      if (chart2 === false) {
        manualTicksX = chart.manualTicksX
      } else {
        manualTicksX = chart.manualTicksX2
      }
    } else {
      manualTicksX = null
    }

    if (chart.manualTicksY !== undefined) {
      if (chart2 === false) {
        manualTicksY = chart.manualTicksY
      } else {
        manualTicksY = chart.manualTicksY2
      }
    } else {
      manualTicksY = null
    }

    const xAxisLabelSideways =
      chart.xAxisLabelSideways !== undefined ? chart.xAxisLabelSideways : 0

    const xDomain =
      chart.xDomain === undefined ? ["auto", "auto"] : chart.xDomain

    const type = chart.type !== undefined ? chart.type : ["category", "number"]

    const xTickCount = chart.xTickCount !== undefined ? chart.xTickCount : 5

    const minTickGapX = chart.minTickGapX !== undefined ? chart.minTickGapX : 5

    const chartOrientation =
      chart.layout !== undefined ? chart.layout : "horizontal"

    const dataKeyXY =
      chart.dataKeyXY !== undefined ? chart.dataKeyXY : ["name", null]

    const stacked = chart.stacked !== undefined ? chart.stacked : null

    const yAxisWidth = chart.yAxisWidth !== undefined ? chart.yAxisWidth : 40

    const chartMargins =
      chart.margins !== undefined ? chart.margins : [10, 30, 40, 0]

    const xAxisOffset =
      chart.xAxisOffset === undefined ? -10 : chart.xAxisOffset

    const yAxisOffset =
      chart.yAxisOffset === undefined ? -10 : chart.yAxisOffset

    const xAxisHeight = chart.xAxisHeight !== undefined ? chart.xAxisHeight : 30

    const xTickFontSize =
      chart.xTickFontSize !== undefined ? chart.xTickFontSize : "1.125rem"

    const tickDy = chart.tickDy !== undefined ? chart.tickDy : 0

    const tickDx = chart.tickDx !== undefined ? chart.tickDx : 0

    const xAxisAngle = chart.xAxisAngle !== undefined ? chart.xAxisAngle : 0

    const interval =
      chart.interval !== undefined ? chart.interval : "preserveEnd"

    return (
      <div style={{ width: chartWidth, height: chartHeight }}>
        <ResponsiveContainer>
          <BarChart
            data={chart.data}
            layout={chartOrientation}
            margin={{
              top: chartMargins[0],
              right: chartMargins[1],
              left: chartMargins[2],
              bottom: chartMargins[3],
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              interval={interval}
              height={xAxisHeight}
              type={type[0]}
              dataKey={dataKeyXY[0]}
              xAxisId="0"
              ticks={manualTicksX}
              domain={xDomain}
              minTickGap={minTickGapX}
              tickCount={xTickCount}
              angle={xAxisAngle}
              tick={{ dy: tickDy, dx: tickDx, fontSize: xTickFontSize }}
            >
              <Label
                value={chart.xAxisLabel}
                position="insideBottomRight"
                offset={xAxisOffset}
                angle={xAxisLabelSideways}
              ></Label>{" "}
            </XAxis>
            <YAxis
              type={type[1]}
              dataKey={dataKeyXY[1]}
              yAxisId="1"
              width={yAxisWidth}
              ticks={manualTicksY}
            >
              <Label
                value={chart.yAxisLabel}
                position="insideLeft"
                offset={yAxisOffset}
              ></Label>{" "}
            </YAxis>
            <Tooltip />
            <Legend />
            {chart.names.map((chartBar, index) => (
              <Bar
                yAxisId="1"
                xAxisId="0"
                key={index}
                stackId={stacked}
                type="monotone"
                dataKey={`bar${index + 1}`}
                name={chartBar}
                fill={chooseColor(index)}
                fillOpacity="1"
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  }
}
