import React, { PureComponent } from "react"
import {
  Area,
  AreaChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { data } from "./data/AreaChartData"

export default class AreaChartComp extends PureComponent {
  constructor(props) {
    super()

    const defaultColorPicker = [
      "#82ca9d",
      "#ff1900",
      "#eb34c0",
      "#6f00ff",
      "#f95d6a",
      "#00ff0d",
      "#2f4b7c",
      "#ffd900",
      "#ff7300",
      "#444d46",
      "#46635e",
    ]

    const chartName = props.chart

    const chart = data.find((object) => object.name === chartName)

    if (chart === undefined) {
      console.log("Area-chart: Chart not found")
      return null
    }

    const colorPicker =
      chart.colorPicker !== undefined ? chart.colorPicker : defaultColorPicker
    let original = [...colorPicker]
    const currentColor = chart.names.map((names) => true)

    this.state = {
      chart: chart,
      current: currentColor,
      original: original,
      colorsOn: currentColor.length,
    }
  }

  render() {
    if (this.state === null) {
      console.log("Area-chart: Chart not found")
      return (
        <div style={{ color: "red", margin: "auto", width: "30%" }}>
          Kuvaajaa ei löytynyt{" "}
        </div>
      )
    }

    const chart = this.state.chart

    const labelSideways = chart.yAxisLabel.length > 1 ? -90 : 0

    const xAxisOffset =
      chart.xAxisOffset === undefined ? -10 : chart.xAxisOffset

    const chooseColor = (index) => {
      let colorOnOff = this.state.current[index]
      return colorOnOff ? this.state.original[index] : "rgba(0,0,0,0)"
    }

    const chartHeight = chart.height ? chart.height : 400
    const chartWidth = chart.width ? `${chart.width}` : "100%"

    const chart2 = this.props.source !== undefined ? true : false

    var yDomain
    var manualTicksY
    var manualTicksX

    if (chart2 === true) {
      if (chart.yDomain2 === undefined) {
        yDomain = ["auto", "auto"]
      } else {
        yDomain = chart.yDomain2
      }
    } else {
      if (chart.yDomain === undefined) {
        yDomain = ["auto", "auto"]
      } else {
        yDomain = chart.yDomain
      }
    }

    if (chart.manualTicksY !== undefined) {
      if (chart2 === false) {
        manualTicksY = chart.manualTicksY
      } else {
        manualTicksY = chart.manualTicksY2
      }
    } else {
      manualTicksY = null
    }

    if (chart.manualTicksX !== undefined) {
      if (chart2 === false) {
        manualTicksX = chart.manualTicksX
      } else {
        manualTicksX = chart.manualTicksX2
      }
    } else {
      manualTicksX = null
    }

    const xDomain =
      chart.xDomain === undefined ? ["auto", "auto"] : chart.xDomain

    const scale = this.props.scale !== undefined ? this.props.scale : "auto"

    const type = chart.type !== undefined ? chart.type : ["category", "number"]

    const yTickCount = chart.yTickCount !== undefined ? chart.yTickCount : 5

    const xTickCount = chart.xTickCount !== undefined ? chart.xTickCount : 5

    const fontSize = chart.fontSize !== undefined ? chart.fontSize : "1.125rem"

    const xAxisLabelSideways =
      chart.xAxisLabelSideways !== undefined ? chart.xAxisLabelSideways : 0

    const minTickGapX = chart.minTickGapX !== undefined ? chart.minTickGapX : 5

    const chooseDashReference =
      chart.chooseDashReference !== undefined ? chart.chooseDashReference : null

    const linesThatBelongToRightYAxis =
      chart.yAxisId2 !== undefined ? chart.yAxisId2 : null

    const xAxisHeight = chart.xAxisHeight !== undefined ? chart.xAxisHeight : 30

    const yAxisWidth = chart.yAxisWidth !== undefined ? chart.yAxisWidth : 30

    return (
      <div style={{ width: chartWidth, height: chartHeight }}>
        <ResponsiveContainer>
          <AreaChart
            data={chart.data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              angle={xAxisLabelSideways}
              height={xAxisHeight}
              dataKey="name"
              xAxisId="0"
              type={type[0]}
              domain={xDomain}
              minTickGap={minTickGapX}
              tickCount={xTickCount}
              ticks={manualTicksX}
            >
              {" "}
              <Label
                value={chart.xAxisLabel}
                position="insideBottomRight"
                offset={xAxisOffset}
              ></Label>{" "}
            </XAxis>
            <YAxis
              style={{
                fontSize: "0.8rem",
              }}
              yAxisId="0"
              width={yAxisWidth}
              ticks={manualTicksY}
              domain={yDomain}
              type={type[1]}
              tickCount={yTickCount}
              scale={scale}
            >
              {" "}
              <Label
                value={chart.yAxisLabel}
                position="insideLeft"
                angle={labelSideways}
                dy={chart.yAxisOffset}
                fontSize={fontSize}
              ></Label>{" "}
            </YAxis>
            <Tooltip />
            <Legend iconType="plainline" />
            {chart.names.map((chartArea, index) => (
              <Area
                yAxisId="0"
                xAxisId="0"
                key={index}
                type="monotone"
                dataKey={`area${index + 1}`}
                stackId="1"
                name={chartArea}
                stroke={chooseColor(index)}
                fill={chooseColor(index)}
                fillOpacity="1"
              />
            ))}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    )
  }
}
