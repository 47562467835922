import React from "react"
import styled from "styled-components"
import { normalizeExerciseId } from "../util/strings"

const Heading = styled.div`
  margin: 6rem 0 2rem 0;
  & h3 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: clamp(20px, 3vw, 2.2rem);
    color: #333;
    text-align: center;
  }
`

const SubHeading = ({ children }) => {
  let text = "unknown heading"
  try {
    text = children.find((o) => typeof o === "string") || "unknown heading"
  } catch (e) {}
  const id = `heading-${normalizeExerciseId(text)}`
  return (
    <Heading>
      <h3 className="material-header" id={id}>
        {children}{" "}
      </h3>
    </Heading>
  )
}

export default SubHeading
