import { Paper } from "@material-ui/core"
import Quiz from "moocfi-quizzes"
import React from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import CourseSettings from "../../course-settings"
import LoginControls from "../components/LoginControls"
import LoginStateContext from "../contexes/LoginStateContext"
import { accessToken } from "../services/moocfi"
import { normalizeExerciseId } from "../util/strings"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

const StyledPaper = styled(Paper)`
  overflow: hidden;
  margin: 4rem 0 4rem 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 40px -12px !important;
  border-radius: 1rem !important;
  background: #f5f9fb !important;
  text-align: center;
  padding-bottom: 2rem;

  p {
    padding: 0 1rem;
    line-height: 1.5;
    margin-bottom: 2rem;
  }

  h2 {
    text-transform: none !important;
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-weight: 500;
    margin: 1rem 0 0.8rem 0;
  }
`

const LoginControlsContainer = styled.div`
  display: flex;
  justify-content: center;
  line-height: 20.6px;
  gap: 10px;
`

class QuizPartial extends React.Component {
  static contextType = LoginStateContext

  render() {
    const { id, t } = this.props
    const alternativeQuizLabel = CourseSettings.alternativeQuizLabel
    let languageId = "en_US"
    if (CourseSettings.language === "fi") {
      languageId = "fi_FI"
    }
    const loginPrompt = (
      <div style={{ padding: "1rem", textAlign: "center" }}>
        <h2>{t("loginToSeeExercise")}</h2>
        <p>{t("loginToSeeExerciseExplanation")}</p>
        <LoginControls />
      </div>
    )

    if (!id) {
      return <div>There should be quiz here but no quiz id is specified.</div>
    }
    return (
      <StyledPaper id={normalizeExerciseId(`quiz-${id}`)}>
        <Quiz
          id={id}
          alternativeQuizLabel={alternativeQuizLabel}
          languageId={languageId}
          accessToken={accessToken()}
          backendAddress="https://quizzes.mooc.fi"
          customContent={{ Login: loginPrompt }}
        >
          {this.props.children}
        </Quiz>
      </StyledPaper>
    )
  }
}

export default withTranslation("common")(withSimpleErrorBoundary(QuizPartial))
