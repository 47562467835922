import { graphql } from "gatsby"
import React from "react"
import styled, { keyframes } from "styled-components"
import PagesContext from "../../contexes/PagesContext"
import "../../templates/remark.css"
import { respond } from "../../_respond"
import H3 from "../Headers/H3"
import TopicContent from "./TopicContent"

const topic = {
  1: {
    idx: "1.",
    title: "Talouden maailma",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words which.",
    color: "#34BF9F",
    textcolor: "dark",
  },
  2: {
    idx: "2.",
    title: "Markkinat ja hinnanmuodostus",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words which.",
    color: "rgb(255, 209, 90)",
    textcolor: "dark",
  },
  3: {
    idx: "3.",
    title: "Ulkoisvaikutukset ja hinnanmuodostus",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words which.",
    color: "#2D4CAD",
    textcolor: "light",
  },
  4: {
    idx: "4.",
    title: "Rahoitusmarkkinat ja investoiminen",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words which.",
    color: "#7C73D9",
    textcolor: "light",
  },
  5: {
    idx: "5.",
    title: "Talouskasvu",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words which.",
    color: "#4E84B2",
    textcolor: "light",
  },
  6: {
    idx: "6.",
    title: "Suhdannevaihtelut",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words which.",
    color: "#50E3C2",
    textcolor: "dark",
  },
  7: {
    idx: "7.",
    title: "Globaalit haasteet",
    subtitle:
      "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humor, or randomized words which.",
    color: "#4C76C9",
    textcolor: "light",
  },
}

const openAnimation = keyframes`
0% { opacity: 0; }
100% { opacity: 1; }
`

const slideDown = keyframes`
from { opacity: 0; height: 0; padding: 0;}
to { opacity: 1; height: 100%; padding: 10px;}
`

const HeroSection = styled.div`
  padding: 8rem 6rem;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background: ${(props) => props.color};

  details[open] summary ~ * {
    animation: ${openAnimation} 0.3s ease-in-out;
    color: ${(props) => (props.textcolor === "light" ? "#f9f9f9" : "#333")};
  }

  details[open] > ul {
    animation-name: ${slideDown};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  details summary::-webkit-details-marker {
    display: none;
  }

  details[open] > summary {
    color: ${(props) => (props.textcolor === "light" ? "white" : "#1c1c1c")};
  }

  details summary {
    width: 100%;
    padding: 1rem 0;
    border-top: 2px solid
      ${(props) => (props.textcolor === "light" ? "#f9f9f9" : "#333")};
    position: relative;
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 400;
    list-style: none;
    color: ${(props) => (props.textcolor === "light" ? "white" : "black")};
  }

  details summary:after {
    content: "+";
    color: ${(props) => (props.textcolor === "light" ? "white" : "black")};
    position: absolute;
    font-size: 1.75rem;
    line-height: 0;
    margin-top: 0.75rem;
    right: 0;
    font-weight: 200;
    transform-origin: center;
    transition: 200ms linear;
  }
  details[open] summary:after {
    transform: rotate(45deg);
    font-size: 2rem;
  }
  details summary {
    outline: 0;
  }

  details ul {
    margin: 0 0 1.5rem 1.5rem;
    padding: 0 !important;
  }

  details ul li {
    font-size: 1.1rem;
    font-family: "Lato", sans-serif;
    margin: 0 0 0.6rem;
    padding-left: 8px;
    list-style-position: outside;
  }

  ul li::marker {
    content: "⦾";
    text-align: center;
    margin-left: 2rem !important;
  }

  ${respond.mobile`
  padding: 2rem;
  grid-template-columns: 1fr;
  `}

  ${respond.xs`
  padding: 4rem 6rem;
  grid-template-columns: 1fr;
`}

  ${respond.sm`
`}

${respond.md`
grid-template-columns: repeat(2, 1fr);
`}

${respond.lg`
padding: 8rem 6rem;
grid-template-columns: repeat(2, 1fr);
`}
`

const HeroText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0rem 4rem 4rem 0;

  H3 {
    color: ${(props) => (props.textcolor === "light" ? "white" : "#333")};
  }

  span {
    font-family: "Lato", sans-serif;
    font-size: 18px;
    line-height: 1.4;
    color: #333;
  }

  h2 {
    text-align: center !important; /* TO-DO: Find a work arround this */
    margin-bottom: 2rem;

    ${respond.md`
  text-align: left !important;
  `}
  }

  ${respond.mobile`
  padding: 0rem 0rem 2rem 0;
  `}

  ${respond.xs`
  width: 100%;
  margin-bottom: 1rem;

`}

  ${respond.md`
  text-align: left;
  margin-bottom: 0;

`}

  ${respond.lg`
  width: 100%;
  text-align: left;
  margin-bottom: 0;
`}
`
const VideoWrapper = styled.div`
  padding: 0.5rem;
`

export default (props) => (
  <PagesContext.Consumer>
    {(value) => {
      const frontmatter = value.current.frontmatter

      let color = topic[props.topic].color
      let licolor = topic[props.topic].licolor
      let idx = topic[props.topic].idx
      let textcolor = topic[props.topic].textcolor

      const children = React.Children.toArray(props.children)

      return (
        <>
          <HeroSection color={color} textcolor={textcolor}>
            <HeroText textcolor={textcolor}>
              <H3> {frontmatter.topic} </H3> <span> {children[0]} </span>{" "}
            </HeroText>{" "}
            <VideoWrapper> {children[1]} </VideoWrapper>{" "}
          </HeroSection>{" "}
          <TopicContent color={color} licolor={licolor} idx={idx}>
            {" "}
          </TopicContent>{" "}
        </>
      )
    }}
  </PagesContext.Consumer>
)

export const pageQuery = graphql`
  query ($path: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      html
      frontmatter {
        path
        title
        topic
      }
      fileAbsolutePath
    }
    allPages: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
            topic
          }
        }
      }
    }
  }
`
