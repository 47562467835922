import React from "react"
import styled from "styled-components"

const TeaserWrapper = styled.div`
  width: 100%;
  height: 20rem;
  height: 16rem;
  padding: 0 1.4rem;
  box-shadow: ${(props) =>
    props.state
      ? "0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);"
      : "0"};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
`

const TeaserIcon = styled.div`
  width: 70px;
  height: 70px;
  display: grid;
  align-content: center;
  justify-content: center;
  background: ${(props) => props.background};
  border-radius: 50px;
  align-self: center;
`

const TeaserText = styled.div`
  margin-top: 1.6rem;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0.5rem 1rem;
  font-weight: 400;
  color: #202020;

  p {
    font-size: 20px;
    line-height: 1.525;
    text-align: center;
  }

  H5 {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
    margin-bottom: 0.6rem;
    opacity: 0.9;
  }
`

export default ({ icon, index, title, text, state, background }) => {
  return (
    <TeaserWrapper state={state}>
      <TeaserIcon background={background}> {icon} </TeaserIcon>{" "}
      <TeaserText>
        <p>{text}</p>
      </TeaserText>{" "}
    </TeaserWrapper>
  )
}
