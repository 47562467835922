import React from "react"
import styled from "styled-components"
import { PrimaryButton } from "../../components/Buttons"
import { respond } from "../../_respond"
import Thumbnail02 from "./Thumbnail02.png"

const ButtonWrapper = styled.div`
  position: absolute;
  margin-top: 2rem;
  padding-bottom: 1rem;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -60%);
`

const HeroSection = styled.div`
  /*   background-image: url(${Thumbnail02});
  background-color: #4fe3c1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; */
  /* padding: 6rem; */
  min-height: 300px;
  width: 100%;
  position: relative;
  height: 73vh;
  /*   padding: 6rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: left;
  align-text: center;
 */

  ${respond.mobile`
  height: 45vh;
`}

  H1 {
    color: #f1f1f1;
    font-size: 70px;
    line-height: 75px;
    font-weight: semi-bold;
  }

  H5 {
    font-family: Montserrat;
    color: #f1f1f1;
    font-weight: 500;
    opacity: 0.9;
  }

  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const StyleDiv = styled.div`
  height: 370px;
  display: grid;
  align-self: center;
`

export default (props) => {
  const scrollDown = (e) => {
    e.preventDefault()
    document
      .querySelector(".scrolling-destination-course-grid")
      .scrollIntoView({ behavior: "smooth" })
  }
  return (
    <>
      <HeroSection>
        <img src={Thumbnail02} alt="Miten talous toimii?" aria-hidden="true" />
        <StyleDiv>
          <ButtonWrapper>
            <PrimaryButton
              color="#249279"
              bg="#fff"
              role="link"
              to="/"
              onClick={scrollDown}
            >
              Aloita kurssi
            </PrimaryButton>
          </ButtonWrapper>
        </StyleDiv>
      </HeroSection>
    </>
  )
}
