import { CourseProgressProviderContext } from "moocfi-quizzes/dist/contexes/courseProgressProviderContext"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import CircularProgressBar from "../../components/CircularProgressBar"

const ProgressContainer = styled.div`
  color: #333;
`

const ExerciseProgress = styled.div`
  margin-bottom: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ExerciseCompleted = styled.div`
  opacity: 0.7;
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  font-family: "Josefin Sans", sans-serif;
  display: flex;
  align-items: center;

  span {
    opacity: 0.9 !important;
    padding: 0 !important;
  }

  span:last-of-type {
    opacity: 0.5 !important;
    margin-right: 5px;
  }
`

const ExerciseVisualization = ({ color, topic }) => {
  const context = useContext(CourseProgressProviderContext)

  const [progressData, setProgressData] = useState({
    exercise: [],
  })

  useEffect(() => {
    const progress = context.progress?.courseProgressData
    if (progress !== undefined) {
      setProgressData({
        exercise: progress.exercise_completions_by_section,
      })
    }
  }, [context])

  const sameExercise = progressData.exercise.reduce((acc, cur, index) => {
    if (cur.part === topic) {
      acc.push({
        total: cur.exercises_total,
        completed: cur.exercises_completed,
      })
    }
    return acc
  }, [])

  let total
  let completed

  if (sameExercise) {
    total = sameExercise.reduce((acc, curr) => acc + curr.total, 0)
    completed = sameExercise.reduce((acc, curr) => acc + curr.completed, 0)

    if (total === 0 && context.progress?.courseProgressData?.exercises) {
      total = context.progress.courseProgressData.exercises.filter(
        (e) => e.part === topic,
      ).length
    }
  }

  if (context.loading) {
    return <div>Loading...</div>
  }

  if (context.error) {
    return <div>Error while fetching progress data.</div>
  }

  return (
    <ProgressContainer>
      <ExerciseProgress>
        {/* <ExerciseDone>{`${completed} exercise done`}</ExerciseDone> */}
        {/*          <ExerciseCompleted>{`${completed}/${total} exercise done`}</ExerciseCompleted> */}
        <ExerciseCompleted>
          <CircularProgressBar point={completed} max={total} color={color} />
          <span>{completed}</span>
          <span>{`/${total}`}</span> tehtävää tehty
        </ExerciseCompleted>
      </ExerciseProgress>
    </ProgressContainer>
  )
}

export default ExerciseVisualization
