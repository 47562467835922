export const data = [
  {
    name: "kuvio-7.13",
    height: 600,
    width: "100%",
    xAxisLabel: "tulot milj.",
    yAxisLabel: "",
    yDomain: [0, 160000],
    xDomain: ["dataMin", "dataMax"],
    yAxisOffset: -35,
    xAxisOffset: 5,
    dataKeyXY: ["bar1", "name"],
    type: ["number", "category"], // [xAxis, yAxis]
    yTickCount: 8,
    layout: "vertical",
    xTickCount: 8,
    xAxisHeight: 60,
    margins: [10, 30, 60, 0],
    manualTicksX: [0, 5, 10, 15, 20, 25, 30, 35],
    names: ["Tulot milj."],
    data: [
      {
        name: "Jeffree Star",
        bar1: 15,
      },
      {
        name: "David Dobrik",
        bar1: 15.5,
      },
      {
        name: "Blippi",
        bar1: 17,
      },
      {
        name: "Nastya",
        bar1: 18.5,
      },
      {
        name: "Preston Arsement",
        bar1: 19,
      },
      {
        name: "Markiplier",
        bar1: 19.5,
      },
      {
        name: "Rhett and Link",
        bar1: 20,
      },
      {
        name: "Dude Perfect",
        bar1: 23,
      },
      {
        name: "Mr. Beast",
        bar1: 24,
      },
      {
        name: "Ryan Kaji",
        bar1: 29.5,
      },
    ],
  },
  {
    name: "old-kuvio-4.11a",
    height: 500,
    width: "100%",
    xAxisLabel: "Vuosi",
    yAxisLabel: "%",
    yDomain: [0, 120],
    xDomain: ["dataMin", "dataMax"],
    yAxisOffset: 0,
    xAxisOffset: 5,
    layout: "horizontal",
    type: ["category", "number"], // [xAxis, yAxis]
    colorPicker: ["#82ca9d", "#ff1900", "#0080ff", "#323329"],
    yTickCount: 8,
    xTickCount: 8,
    minTickGapX: 15,
    yAxisWidth: 50,
    margins: [20, 5, 20, 5],
    xAxisHeight: 50,
    stacked: "1",
    manualTicksX: [
      1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010, 2020,
    ],
    names: [
      "Keskuspankin velat pankeille",
      "Yleisön (suomalaiset) talletukset pankeissa",
      "Liikkeeseen laskettu setelistö",
      "Liikkeeseen lasketut kolikot",
    ],
    data: [
      {
        name: 1920,
        bar1: 0.2,
        bar2: 32.3,
        bar3: 9.8,
      },
      {
        name: 1921,
        bar1: 0.2,
        bar2: 29.2,
        bar3: 8.4,
      },
      {
        name: 1922,
        bar1: 0.2,
        bar2: 29.7,
        bar3: 8,
      },
      {
        name: 1923,
        bar1: 0.2,
        bar2: 30.7,
        bar3: 7.1,
      },
      {
        name: 1924,
        bar1: 0.1,
        bar2: 31.6,
        bar3: 6.2,
      },
      {
        name: 1925,
        bar1: 0.1,
        bar2: 32.8,
        bar3: 6,
      },
      {
        name: 1926,
        bar1: 0.3,
        bar2: 36.1,
        bar3: 5.9,
      },
      {
        name: 1927,
        bar1: 0.3,
        bar2: 37.8,
        bar3: 6,
      },
      {
        name: 1928,
        bar1: 0.2,
        bar2: 39.6,
        bar3: 5.5,
      },
      {
        name: 1929,
        bar1: 0,
        bar2: 42.5,
        bar3: 5.1,
      },
      {
        name: 1930,
        bar1: 0,
        bar2: 48.6,
        bar3: 5.3,
      },
      {
        name: 1931,
        bar1: 0,
        bar2: 53.3,
        bar3: 6.1,
      },
      {
        name: 1932,
        bar1: 0,
        bar2: 51.9,
        bar3: 5,
      },
      {
        name: 1933,
        bar1: 1.2,
        bar2: 50.6,
        bar3: 5.1,
      },
      {
        name: 1934,
        bar1: 0.5,
        bar2: 48.1,
        bar3: 4.9,
      },
      {
        name: 1935,
        bar1: 1.1,
        bar2: 48.6,
        bar3: 5,
      },
      {
        name: 1936,
        bar1: 1.5,
        bar2: 48.7,
        bar3: 5.4,
      },
      {
        name: 1937,
        bar1: 2.5,
        bar2: 47.7,
        bar3: 5.8,
      },
      {
        name: 1938,
        bar1: 2,
        bar2: 49.8,
        bar3: 5.4,
      },
      {
        name: 1939,
        bar1: 1,
        bar2: 51,
        bar3: 10.8,
      },
      {
        name: 1940,
        bar1: 1.2,
        bar2: 54.1,
        bar3: 13,
      },
      {
        name: 1941,
        bar1: 1.8,
        bar2: 47.9,
        bar3: 13.8,
      },
      {
        name: 1942,
        bar1: 1.4,
        bar2: 46.5,
        bar3: 14.6,
      },
      {
        name: 1943,
        bar1: 0.6,
        bar2: 46.2,
        bar3: 12.8,
      },
      {
        name: 1944,
        bar1: 0.6,
        bar2: 50.7,
        bar3: 16.5,
      },
      {
        name: 1945,
        bar1: 1.8,
        bar2: 44.8,
        bar3: 9.3,
      },
      {
        name: 1946,
        bar1: 0.6,
        bar2: 33.9,
        bar3: 8.3,
      },
      {
        name: 1947,
        bar1: 0.9,
        bar2: 31.6,
        bar3: 8.5,
      },
      {
        name: 1948,
        bar1: 0.3,
        bar2: 26.4,
        bar3: 6.6,
      },
      {
        name: 1949,
        bar1: 0.4,
        bar2: 30.9,
        bar3: 6.7,
      },
      {
        name: 1950,
        bar1: 0.2,
        bar2: 28.7,
        bar3: 6.3,
      },
      {
        name: 1951,
        bar1: 0.3,
        bar2: 27.4,
        bar3: 5.3,
      },
      {
        name: 1952,
        bar1: 0.3,
        bar2: 29.6,
        bar3: 5.6,
      },
      {
        name: 1953,
        bar1: 0.3,
        bar2: 34.2,
        bar3: 5.5,
      },
      {
        name: 1954,
        bar1: 0.3,
        bar2: 36,
        bar3: 5.3,
      },
      {
        name: 1955,
        bar1: 0.8,
        bar2: 37.1,
        bar3: 5.6,
      },
      {
        name: 1956,
        bar1: 0.1,
        bar2: 34.5,
        bar3: 5.5,
      },
      {
        name: 1957,
        bar1: 0.2,
        bar2: 32.3,
        bar3: 5,
      },
      {
        name: 1958,
        bar1: 0.1,
        bar2: 33.8,
        bar3: 4.9,
      },
      {
        name: 1959,
        bar1: 0.3,
        bar2: 37.1,
        bar3: 4.9,
      },
      {
        name: 1960,
        bar1: 0.1,
        bar2: 37.8,
        bar3: 4.5,
      },
      {
        name: 1961,
        bar1: 0.3,
        bar2: 38.2,
        bar3: 4.6,
      },
      {
        name: 1962,
        bar1: 0.3,
        bar2: 38.8,
        bar3: 3.9,
      },
      {
        name: 1963,
        bar1: 0,
        bar2: 38.5,
        bar3: 4.4,
      },
      {
        name: 1964,
        bar1: 0.3,
        bar2: 38.3,
        bar3: 4.2,
      },
      {
        name: 1965,
        bar1: 0.1,
        bar2: 38.5,
        bar3: 3.9,
      },
      {
        name: 1966,
        bar1: 0.1,
        bar2: 40.4,
        bar3: 3.9,
      },
      {
        name: 1967,
        bar1: 0.6,
        bar2: 40.3,
        bar3: 3.4,
      },
      {
        name: 1968,
        bar1: 0.5,
        bar2: 39.4,
        bar3: 3.2,
      },
      {
        name: 1969,
        bar1: 0.3,
        bar2: 39.1,
        bar3: 3.2,
      },
      {
        name: 1970,
        bar1: 0.4,
        bar2: 39.9,
        bar3: 2.9,
      },
      {
        name: 1971,
        bar1: 0,
        bar2: 41.3,
        bar3: 2.9,
      },
      {
        name: 1972,
        bar1: 0,
        bar2: 41.9,
        bar3: 3,
      },
      {
        name: 1973,
        bar1: 0,
        bar2: 39.8,
        bar3: 2.7,
      },
      {
        name: 1974,
        bar1: 0,
        bar2: 37,
        bar3: 2.5,
      },
      {
        name: 1975,
        bar1: 0,
        bar2: 36.7,
        bar3: 2.4,
      },
      {
        name: 1976,
        bar1: 0.9,
        bar2: 35.4,
        bar3: 2.2,
      },
      {
        name: 1977,
        bar1: 0.9,
        bar2: 36,
        bar3: 2.2,
      },
      {
        name: 1978,
        bar1: 1.7,
        bar2: 37.3,
        bar3: 2.4,
      },
      {
        name: 1979,
        bar1: 1.8,
        bar2: 38,
        bar3: 2.3,
      },
      {
        name: 1980,
        bar1: 1.9,
        bar2: 37.6,
        bar3: 2.3,
        bar4: 0.2,
      },
      {
        name: 1981,
        bar1: 1.7,
        bar2: 38.4,
        bar3: 2.3,
        bar4: 0.2,
      },
      {
        name: 1982,
        bar1: 1.9,
        bar2: 38.9,
        bar3: 2.2,
        bar4: 0.2,
      },
      {
        name: 1983,
        bar1: 2,
        bar2: 39.8,
        bar3: 2.1,
        bar4: 0.1,
      },
      {
        name: 1984,
        bar1: 3.7,
        bar2: 40.9,
        bar3: 2.1,
        bar4: 0.2,
      },
      {
        name: 1985,
        bar1: 3.5,
        bar2: 44.1,
        bar3: 2.1,
        bar4: 0.2,
      },
      {
        name: 1986,
        bar1: 2.6,
        bar2: 44,
        bar3: 2.1,
        bar4: 0.2,
      },
      {
        name: 1987,
        bar1: 2.9,
        bar2: 45.9,
        bar3: 2.3,
        bar4: 0.2,
      },
      {
        name: 1988,
        bar1: 4.2,
        bar2: 50.3,
        bar3: 2.3,
        bar4: 0.2,
      },
      {
        name: 1989,
        bar1: 5.2,
        bar2: 48.5,
        bar3: 2.4,
        bar4: 0.2,
      },
      {
        name: 1990,
        bar1: 3.3,
        bar2: 47.9,
        bar3: 2.5,
        bar4: 0.2,
      },
      {
        name: 1991,
        bar1: 2.4,
        bar2: 53.2,
        bar3: 2.6,
        bar4: 0.2,
      },
      {
        name: 1992,
        bar1: 4,
        bar2: 54.3,
        bar3: 2.6,
        bar4: 0.2,
      },
      {
        name: 1993,
        bar1: 1.6,
        bar2: 54.5,
        bar3: 2.6,
        bar4: 0.3,
      },
      {
        name: 1994,
        bar1: 1.5,
        bar2: 49.5,
        bar3: 2.3,
        bar4: 0.3,
      },
      {
        name: 1995,
        bar1: 5.2,
        bar2: 51.9,
        bar3: 2.4,
        bar4: 0.2,
      },
      {
        name: 1996,
        bar1: 1.4,
        bar2: 48.7,
        bar3: 2.5,
        bar4: 0.3,
      },
      {
        name: 1997,
        bar1: 1.6,
        bar2: 46.5,
        bar3: 2.4,
        bar4: 0.2,
      },
      {
        name: 1998,
        bar1: 1.8,
        bar2: 44.2,
        bar3: 2.2,
        bar4: 0.2,
      },
      {
        name: 1999,
        bar1: 3.8,
        bar2: 49.7,
        bar3: 2.4,
        bar4: 0.2,
      },
      {
        name: 2000,
        bar1: 1.8,
        bar2: 47.8,
        bar3: 2.2,
        bar4: 0.2,
      },
      {
        name: 2001,
        bar1: 2.7,
        bar2: 45.3,
        bar3: 1.6,
        bar4: 0.2,
      },
      {
        name: 2002,
        bar1: 2.5,
        bar2: 46.8,
        bar3: 2,
        bar4: 0.2,
      },
      {
        name: 2003,
        bar1: 1.4,
        bar2: 48.3,
        bar3: 2.4,
        bar4: 0.2,
      },
      {
        name: 2004,
        bar1: 2,
        bar2: 48.2,
        bar3: 2.8,
        bar4: 0.2,
      },
      {
        name: 2005,
        bar1: 2.1,
        bar2: 49.1,
        bar3: 3.3,
        bar4: 0.2,
      },
      {
        name: 2006,
        bar1: 2.2,
        bar2: 48.3,
        bar3: 3.8,
        bar4: 0.2,
      },
      {
        name: 2007,
        bar1: 3.9,
        bar2: 51,
        bar3: 4,
        bar4: 0.2,
      },
      {
        name: 2008,
        bar1: 3.5,
        bar2: 55.2,
        bar3: 4.3,
        bar4: 0.2,
      },
      {
        name: 2009,
        bar1: 4.6,
        bar2: 59.6,
        bar3: 5.2,
        bar4: 0.3,
      },
      {
        name: 2010,
        bar1: 11.5,
        bar2: 61.7,
        bar3: 5.6,
        bar4: 0.3,
      },
      {
        name: 2011,
        bar1: 36.2,
        bar2: 62.5,
        bar3: 5.6,
        bar4: 0.3,
      },
      {
        name: 2012,
        bar1: 31.8,
        bar2: 62.4,
        bar3: 5.9,
        bar4: 0.3,
      },
      {
        name: 2013,
        bar1: 13.6,
        bar2: 62.6,
        bar3: 6,
        bar4: 0.3,
      },
      {
        name: 2014,
        bar1: 7.4,
        bar2: 64.1,
        bar3: 6.2,
        bar4: 0.3,
      },
      {
        name: 2015,
        bar1: 21.3,
        bar2: 67.9,
        bar3: 6.5,
        bar4: 0.3,
      },
      {
        name: 2016,
        bar1: 21.9,
        bar2: 67.6,
        bar3: 6.7,
        bar4: 0.3,
      },
      {
        name: 2017,
        bar1: 33.9,
        bar2: 68.4,
        bar3: 6.8,
        bar4: 0.3,
      },
      {
        name: 2018,
        bar1: 31.9,
        bar2: 67.7,
        bar3: 6.9,
        bar4: 0.3,
      },
      {
        name: 2019,
        bar1: 36.6,
        bar2: 68.5,
        bar3: 7,
        bar4: 0.2,
      },
    ],
  },
  {
    name: "kuvio-5.markkina_arvo_1997",
    colorPicker: ["#4D84B2"],
    height: 500,
    width: "100%",
    xAxisLabel: "Markkina-arvo (miljardia dollaria)",
    yAxisLabel: "",
    yDomain: [0, 400],
    xDomain: ["dataMin", "dataMax"],
    yAxisOffset: -35,
    xAxisOffset: 5,
    dataKeyXY: ["bar1", "name"],
    type: ["number", "category"], // [xAxis, yAxis]
    yTickCount: 5,
    layout: "vertical",
    minTickGapX: 0,
    xTickCount: 5,
    xAxisHeight: 50,
    yAxisWidth: 100,
    manualTicksX: [0, 50, 100, 150, 200, 250],
    names: ["Markkina-arvo 1997 (miljardia dollaria)"],
    data: [
      {
        name: "Novartis",
        bar1: 104,
      },
      {
        name: "Toyota",
        bar1: 117,
      },
      {
        name: "Merck",
        bar1: 121,
      },
      {
        name: "Nippon Tele",
        bar1: 146,
      },
      {
        name: "Intel Corporation",
        bar1: 151,
      },
      {
        name: "Coca-Cola Company",
        bar1: 151,
      },
      {
        name: "Exxon Mobil",
        bar1: 158,
      },
      {
        name: "Microsoft",
        bar1: 160,
      },
      {
        name: "Royal Dutch Shell",
        bar1: 191,
      },
      {
        name: "General Electric",
        bar1: 222.7,
      },
    ],
  },
  {
    name: "kuvio-5.markkina_arvo_2020",
    colorPicker: ["#4D84B2"],
    height: 500,
    width: "100%",
    xAxisLabel: "Markkina-arvo (miljardia dollaria)",
    yAxisLabel: "",
    yDomain: [0, 160000],
    xDomain: ["dataMin", "dataMax"],
    yAxisOffset: -35,
    xAxisOffset: 5,
    dataKeyXY: ["bar1", "name"],
    type: ["number", "category"], // [xAxis, yAxis]
    yTickCount: 8,
    layout: "vertical",
    xTickCount: 8,
    xAxisHeight: 50,
    yAxisWidth: 100,
    manualTicksX: [0, 500, 1000, 1500, 2000, 2500],
    names: ["Markkina-arvo 2022 (miljardia dollaria)"],
    data: [
      {
        name: "Meta",
        bar1: 436,
      },
      {
        name: "Tencent",
        bar1: 442,
      },
      {
        name: "Johnson & Johnson",
        bar1: 467,
      },
      {
        name: "United Health",
        bar1: 482,
      },
      {
        name: "Berkshire Hathaway",
        bar1: 602,
      },
      {
        name: "Tesla",
        bar1: 698,
      },
      {
        name: "Amazon",
        bar1: 1080,
      },
      {
        name: "Alphabet",
        bar1: 1435,
      },
      {
        name: "Microsoft",
        bar1: 1920,
      },
      {
        name: "Apple",
        bar1: 2212,
      },
    ],
  },
  {
    name: "kuvio-3.10",
    height: 600,
    width: "100%",
    xAxisLabel: "%, BKT",
    yAxisLabel: "",
    yDomain: ["dataMin", "dataMax"],
    xDomain: ["dataMin", "dataMax"],
    yAxisOffset: -35,
    xAxisOffset: 5,
    type: ["category", "number"], // [xAxis, yAxis]
    yTickCount: 8,
    layout: "horizontal",
    tickDy: 15,
    fontSize: "0.8rem",
    xTickFontSize: "0.7rem",
    xTickCount: 12,
    interval: 0,
    xAxisAngle: 90,
    xAxisHeight: 60,
    margins: [10, 30, 60, 0],
    manualTicksY: [0, 10, 20, 30, 40, 50, 60],
    names: ["%, BKT"],
    data: [
      {
        name: "IRL",
        bar1: 24.2,
      },
      {
        name: "CHL",
        bar1: 26.4,
      },
      {
        name: "CHE",
        bar1: 32.8,
      },
      {
        name: "COL",
        bar1: 33,
      },
      {
        name: "KOR",
        bar1: 33.9,
      },
      {
        name: "CRI",
        bar1: 34.3,
      },
      {
        name: "LTU",
        bar1: 34.8,
      },
      {
        name: "LVA",
        bar1: 38.2,
      },
      {
        name: "USA",
        bar1: 38.2,
      },
      {
        name: "JPN",
        bar1: 38.9,
      },
      {
        name: "EST",
        bar1: 39.4,
      },
      {
        name: "ISR",
        bar1: 39.6,
      },
      {
        name: "GBR",
        bar1: 40.3,
      },
      {
        name: "SVK",
        bar1: 40.7,
      },
      {
        name: "CZE",
        bar1: 41.1,
      },
      {
        name: "POL",
        bar1: 41.8,
      },
      {
        name: "NLD",
        bar1: 42,
      },
      {
        name: "ESP",
        bar1: 42.1,
      },
      {
        name: "PRT",
        bar1: 42.5,
      },
      {
        name: "LUX",
        bar1: 42.9,
      },
      {
        name: "SVN",
        bar1: 43.3,
      },
      {
        name: "ISL",
        bar1: 43.4,
      },
      {
        name: "AUS",
        bar1: 44.1,
      },
      {
        name: "DEU",
        bar1: 45,
      },
      {
        name: "HUN",
        bar1: 45.7,
      },
      {
        name: "GRC",
        bar1: 47.9,
      },
      {
        name: "ITA",
        bar1: 48.5,
      },
      {
        name: "AUT",
        bar1: 48.6,
      },
      {
        name: "SWE",
        bar1: 49.1,
      },
      {
        name: "DNK",
        bar1: 49.7,
      },
      {
        name: "NOR",
        bar1: 51.6,
      },
      {
        name: "BEL",
        bar1: 51.8,
      },
      {
        name: "FIN",
        bar1: 53.3,
      },
      {
        name: "FRA",
        bar1: 55.4,
      },
    ],
  },
]
