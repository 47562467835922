import { graphql, StaticQuery } from "gatsby"
import React, { Fragment } from "react"
import rehypeReact from "rehype-react"
import styled from "styled-components"
import getNamedPartials from "../../partials"
import { respond } from "../../_respond"

const ContentWrapper = styled.article`
  text-align: center;
  padding: 2rem 4rem;
  margin: 8rem 0;
  margin-top: 6.8rem;
  position: relative;

  h1 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
    font-size: clamp(35px, 4vw, 50px);
    color: #333;
    margin: 0;
    text-align: center;
    line-height: 1;
  }

  H3 {
    font-weight: bold;
  }

  ${respond.mobile`
  padding: 2rem 0;
  margin: 2rem 0 2rem 0;
  `}

  ${respond.xs`
  padding: 0;
  margin: 2rem 0 2rem 0;
`}

  ${respond.sm`
  padding: 4rem 4rem;
`}

  ${respond.md`
  padding: 4rem 2rem;
  `}

  ${respond.lg`
  padding: 4rem 4rem;
  `}
`

const Header = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 18rem;

  ${respond.lg`
  width: 26rem;
  `}
`

const GridNavigation = ({ data }) => {
  const { frontmatter, htmlAst } = data.markdownRemark
  const partials = getNamedPartials()
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: partials,
  }).Compiler
  return (
    <Fragment>
      <ContentWrapper className="scrolling-destination-course-grid">
        <Header>
          <h1> {frontmatter.title} </h1>
        </Header>{" "}
        {renderAst(htmlAst)}{" "}
      </ContentWrapper>{" "}
    </Fragment>
  )
}

export default function GridQuery(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(frontmatter: { path: { eq: "/grid" } }) {
            htmlAst
            frontmatter {
              path
              title
            }
          }
        }
      `}
      render={(data) => <GridNavigation data={data} {...props} />}
    />
  )
}

/* export default withTranslation("common")(
    withSimpleErrorBoundary(GridNavigation),
  ) */
