// import importAll from 'import-all.macro'

// function getNamedPartials() {
//   const partials = importAll.sync('./*.js')

//   const namedPartials = {}

//   Object.entries(partials)
//     .filter(([k, _]) => k !== './index.js')
//     .forEach(([k, v]) => {
//       const newKey = toKebabCase(k.replace('./', '').replace('.js', ''))
//       namedPartials[newKey] = v.default
//     })

//   function toKebabCase(string) {
//     return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
//   }
//   return namedPartials
// }

// export default getNamedPartials

import Button from "../components/Button"
import ErrorHero from "./404page/index"
import AbStudy from "./AbStudy"
import OnlyForAbGroup from "./AbStudy/OnlyForAbGroup"
import Caption from "./Caption"
import FloatImage from "./Contentpage/FloatingImage"
import FullWidthWrapper from "./Contentpage/FullWidthWrapper"
import Header from "./Contentpage/Header"
import MediumContent from "./Contentpage/MediumContent"
import Quote from "./Contentpage/Quote"
import CourseProgress from "./CourseProgress"
import Deadline from "./Deadline"
import DetailTag from "./DetailTag"
import ExercisesInThisSection from "./ExercisesInThisSection"
import FloatImageRight from "./FloatImageRight"
import GoogleFormLink from "./GoogleFormLink"
import CourseGrid from "./Grid/CourseGrid"
import CourseGridCard from "./Grid/CourseGridCard"
import GridNavigation from "./Grid/index"
import H1 from "./Headers/H1"
import H2 from "./Headers/H2"
import H3 from "./Headers/H3"
import H4 from "./Headers/H4"
import H5 from "./Headers/H5"
import H6 from "./Headers/H6"
import CoreTeam from "./Homepage/CoreTeam"
import CourseDescription from "./Homepage/CourseDescription"
import FAQ from "./Homepage/FAQ"
import HeroSection from "./Homepage/HeroSection"
import Introduction from "./Homepage/Introduction"
import PlaceHolder from "./Homepage/PlaceHolder"
import TeaserQuestion from "./Homepage/Teaser"
import TeaserCard from "./Homepage/TeaserCard"
import Hr from "./Hr"
import InBrowserProgrammingExercise from "./InBrowserProgrammingExercise"
import MoodleExercise from "./MoodleExercise"
import Notice from "./Notice"
import OnlyForCourseVariant from "./OnlyForCourseVariant"
import OnlyForNotLoggedIn from "./OnlyForNotLoggedIn"
import PagesInThisSection from "./PagesInThisSection"
import PleaseLogin from "./PleaseLogin"
import Points from "./Points"
import ProgrammingExercise from "./ProgrammingExercise"
import Quiz from "./Quiz"
import Quiznator from "./Quiznator"
import AreaChart from "./Recharts/AreaChart"
import BarChart from "./Recharts/BarChart"
import ChartSwitcher from "./Recharts/ChartSwitcher"
import LineChart from "./Recharts/LineChart"
import RegistrationLink from "./RegistrationLink"
import RegistrationLink2 from "./RegistrationLink2"
import ResizeImage from "./ResizeMarkdownImage"
import SampleData from "./SampleData"
import SampleOutput from "./SampleOutput"
import SqlTrainerExercise from "./SqlTrainerExercise"
import SubHeading from "./SubHeading"
import Summary from "./Summary"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableTh,
} from "./Table"
import TableOfContents from "./TableOfContents"
import Test from "./Test"
import TextBox from "./TextBox"
import TopicHero from "./Topicpage/HeroSection"
import Vocabulary from "./Vocabulary"
import VocabularyWord from "./Vocabulary/VocabularyWord"
import WorkshopSchedule from "./WorkshopSchedule"
import Youtube from "./Youtube"

const mapping = {
  test: Test,
  "pages-in-this-section": PagesInThisSection,
  "programming-exercise": ProgrammingExercise,
  "text-box": TextBox,
  "sample-data": SampleData,
  "sample-output": SampleOutput,
  "please-login": PleaseLogin,
  "float-image-right": FloatImageRight,
  "exercises-in-this-section": ExercisesInThisSection,
  "ab-study": AbStudy,
  "only-for-ab-group": OnlyForAbGroup,
  "only-for-course-variant": OnlyForCourseVariant,
  "only-for-not-logged-in": OnlyForNotLoggedIn,
  "table-of-contents": TableOfContents,
  "registration-link": RegistrationLink,
  "registration-link-ohja": RegistrationLink2,
  "moodle-exercise": MoodleExercise,
  "sqltrainer-exercise": SqlTrainerExercise,
  "google-form-link": GoogleFormLink,
  "in-browser-programming-exercise": InBrowserProgrammingExercise,
  "workshop-schedule": WorkshopSchedule,
  "vocabulary-word": VocabularyWord,
  "course-grid": CourseGrid,
  "course-grid-card": CourseGridCard,
  "homepage-grid": GridNavigation,
  "teaser-question": TeaserQuestion,
  "teaser-card": TeaserCard,
  "hero-section": HeroSection,
  "floating-image": FloatImage,
  "course-progress": CourseProgress,
  "core-team": CoreTeam,
  "detail-tag": DetailTag,
  faq: FAQ,
  chart: LineChart,
  "area-chart": AreaChart,
  "bar-chart": BarChart,
  "chart-switcher": ChartSwitcher,
  placeholder: PlaceHolder,
  introduction: Introduction,
  "full-width-wrapper": FullWidthWrapper,
  "course-description": CourseDescription,
  header: Header,
  "medium-content": MediumContent,
  "resize-image": ResizeImage,
  quote: Quote,
  "topic-hero": TopicHero,
  "error-hero": ErrorHero,
  "caption-text": Caption,
  "sub-heading": SubHeading,
  vocabulary: Vocabulary,
  notice: Notice,
  youtube: Youtube,
  quiz: Quiz,
  quiznator: Quiznator,
  table: Table,
  tbody: TableBody,
  thead: TableHead,
  tr: TableRow,
  td: TableCell,
  th: TableTh,
  deadline: Deadline,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  hr: Hr,
  points: Points,
  summarys: Summary,
  button: Button,
}

export default () => {
  return mapping
}
