import React from "react"
import styled from "styled-components"
import { respond } from "../../_respond"
import H3 from "../Headers/H3"

//TO-DO: Make Grid responsive without Media queries
//TO-DO: + More Breakpoints

const CourseDescriptionContainer = styled.div`
  margin: 1rem 0;
  padding: 2rem 6rem;
  height: 70vh;
  align-item: center;
  display: grid;
  align-content: center;

  ${respond.mobile`
  padding: 0;
  margin: 0;
`}

  H3 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
  }
`
const DescriptionText = styled.div`
  font-size: 1.2rem;
  line-height: 1.85;
  width: 50%;
  margin: 0 auto;
  color: #202020;
  font-weight: 500;
  text-align: center;

  ${respond.mobile`
    width: 100%;
    padding: 0 1rem;
    font-size: 1.2rem;
    line-height: 1.6;
  `}

  ${respond.xs`
    font-size: 1.2rem;
    line-height: 1.7;
    width: 100%;
  `}

  ${respond.lg`
     width: 50%;
     font-size: 1.3rem;
     line-height: 1.85;
  `}
`

export default (props) => {
  return (
    <CourseDescriptionContainer>
      <H3> {props.title} </H3>{" "}
      <DescriptionText> {props.bodycopy} </DescriptionText>{" "}
    </CourseDescriptionContainer>
  )
}
