import { graphql, Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import PagesContext from "../../contexes/PagesContext"
import "../../templates/remark.css"
import { nthIndex } from "../../util/strings"
import { respond } from "../../_respond"
import H3 from "../Headers/H3"

const Subtitle = styled.div`
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
  font-family: "Lato", sans-serif;
  text-align: center;
  color: #333;
  opacity: 0.8;
`

const TopicContentWrapper = styled.div`
  padding: 4rem 0;
  width: 100%;
  min-height: 40vh;

  ol {
    max-width: 55rem;
    margin: 3rem auto;
    list-style: none;
    counter-reset: subtopic-counter;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    ${respond.mobile`
    margin: 0 auto;
    `}
  }

  ol li {
    counter-increment: subtopic-counter;
    width: 80%;
    display: flex;
    padding: 1.2rem 1.4rem;
    text-align: left;
    background: rgba(0, 0, 0, 0.04);
    margin-bottom: 5px;
    border-radius: 2px;

    ${respond.mobile`
    width: 90%;
    `}

    div {
      width: 100%;
    }

    :hover {
      background: rgba(0, 0, 0, 0.06);
    }

    :active {
      background: rgba(0, 0, 0, 0.08);
    }
  }

  ol li:first-of-type::before {
    margin-right: 1.6rem;
  }

  ol li::before {
    content: "${(props) => props.idx}" counter(subtopic-counter);
    font-weight: 600;
    font-size: 1.4rem;
    font-family: "Josefin Sans", sans-serif;
    color: rgba(0, 0, 0, 0.3);
    line-height: 1.2;
    padding-top: 0.2rem;
    margin-right: 1.4rem;
  }

  H3 {
    text-align: center;
    font-family: "Josefin Sans", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
  }
`
const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
  display: grid;
  align-content: center;

  & span {
    font-weight: 500;
    margin-bottom: 0;
    font-family: "Josefin Sans", sans-serif;
    font-size: clamp(16px, 2vw, 20px);
    line-height: 1.2;
    color: #333;
    opacity: 0.9;
    text-transform: uppercase;
  }
`

export default (props) => (
  <PagesContext.Consumer>
    {(value) => {
      const currentPath = value.current.frontmatter.path
      let sectionPath = currentPath
      const frontmatter = value.current.frontmatter
      const sectionSeparator = nthIndex(currentPath, "/", 2)
      if (sectionSeparator !== -1) {
        sectionPath = currentPath.substr(0, sectionSeparator)
      }
      const sectionPages = value.all
        .filter((o) => {
          return o.path.startsWith(`${sectionPath}/`)
        })
        .sort((a, b) => {
          a = a.path.toLowerCase()
          b = b.path.toLowerCase()

          return a > b ? 1 : b > a ? -1 : 0
        })

      return (
        <TopicContentWrapper
          color={props.color}
          numcolor={props.numcolor}
          idx={props.idx}
        >
          <H3> {frontmatter.title} </H3>{" "}
          <Subtitle>Tämä luku koostuu seuraavista kappaleista</Subtitle>{" "}
          <ol>
            {sectionPages.map((section, index) => (
              <li
                key={index}
                onClick={() => (window.location.href = section.path)}
                style={{ cursor: "pointer" }}
              >
                <StyledLink to={section.path}>
                  <div>
                    <span> {section.title} </span>
                  </div>{" "}
                </StyledLink>
              </li>
            ))}
          </ol>{" "}
        </TopicContentWrapper>
      )
    }}
  </PagesContext.Consumer>
)

export const pageQuery = graphql`
  query ($path: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      html
      frontmatter {
        path
        title
      }
      fileAbsolutePath
    }
    allPages: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
          }
        }
      }
    }
  }
`
