import { CourseProgressProviderContext } from "moocfi-quizzes/dist/contexes/courseProgressProviderContext"
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { respond } from "../../_respond"
import H3 from "../Headers/H3"
import Completed from "./Completed"
import ProgressBar from "./ProgressBar"
import ScoreBoard from "./ScoreBoard"

const ProgressContainer = styled.div`
  margin: 5rem 10rem;
  padding-bottom: 2rem;
  color: #333;

  ${respond.mobile`
  margin: 2rem 2rem;
`}
  ${respond.xs`
  margin: 2rem 3rem;
`} ${respond.sm`
  margin: 2rem 5rem;
`} ${respond.md`
  margin: 2rem 10rem;
`} ${respond.lg`
  margin: 2rem auto;
  max-width: 1140px;
`};

  small {
    color: #757575;
    font-family: "Poppins";
  }

  H3 {
    text-align: center;
  }
`

const ExerciseProgress = styled.div`
  p {
    opacity: 0.8;
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    font-family: "Josefin Sans", sans-serif;
  }
`

const CourseProgressVisualization = () => {
  const data = useContext(CourseProgressProviderContext)

  const [progressData, setProgressData] = useState({
    maxPoints: 85,
    n_points: 0,
    completed: true,
  })

  useEffect(() => {
    const progress = data.progress?.courseProgressData
    if (progress !== undefined) {
      setProgressData({
        exercise_completions: progress.exercise_completions,
        total_exercises: progress.total_exercises,
        max_points: progress.max_points,
        n_points: progress.n_points,
        completed: progress.completed,
      })
    }
  }, [data])
  if (data.loading) {
    return <div>Loading...</div>
  }

  if (data.error) {
    return <div>Error while fetching progress data.</div>
  }

  const completed = data.progress?.courseProgressData?.completed

  return (
    <React.Fragment>
      {completed ? (
        <Completed />
      ) : (
        <ProgressContainer>
          <H3>Edistyminen</H3>
          <ScoreBoard
            point={progressData.n_points}
            max={progressData.max_points}
          />

          <ExerciseProgress>
            <p>Tehtäviä tehty</p>
            <ProgressBar
              label="Finish"
              n={0}
              exercisesDone={progressData.exercise_completions}
              exercisesTotal={83}
            />
          </ExerciseProgress>
        </ProgressContainer>
      )}
    </React.Fragment>
  )
}

export default CourseProgressVisualization
