import React from "react"
import styled from "styled-components"
import PagesContext from "../../contexes/PagesContext"
import { respond } from "../../_respond"
import CourseGridCard from "./CourseGridCard"

const GridContainer = styled.div`
  padding: 2rem 0.5rem;
  margin-top: 0rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  column-gap: 4rem;
  row-gap: 4rem;
  justify-content: center;
  margin-bottom: 4rem;

  ${respond.mobile`
  row-gap: 2rem;
  padding: 0.5rem 0;
  grid-template-columns: repeat(1, minmax(18rem, 40rem));
  `}

  ${respond.xs`
    row-gap: 2rem;
    padding: 2rem 4rem;
    grid-template-columns: minmax(20rem,100%);
  `}

  ${respond.sm`
    width: 100%;
    row-gap: 3rem;
    padding: 0;
    grid-template-columns: repeat(1, minmax(15rem, 34rem));
  `}

  ${respond.md`
  row-gap: 3rem;
  padding: 2rem 2rem;
  grid-template-columns: repeat(2, 28rem);
  `}

  ${respond.lg`
  row-gap: 4rem;
  grid-template-columns: repeat(2, 35rem);
  `}
`

export default function CourseGrid() {
  return (
    <PagesContext.Consumer>
      {(values) => {
        const allPages = values.all.allPages.edges.map(
          (o) => o.node?.frontmatter,
        )
        const coursePages = allPages.filter((page) =>
          page.path.startsWith("/part"),
        )
        const regex = new RegExp("/part-[1-9]$", "gm")
        let parts = coursePages
          .filter((o) => o.path.match(regex))
          .sort((a, b) => {
            return a.path > b.path ? 1 : -1
          })
        const bktLiite = allPages.find((page) => page.path === "/bkt-liite")
        parts = parts.concat(bktLiite)

        return (
          <>
            <GridContainer>
              <CourseGridCard parts={parts} />{" "}
            </GridContainer>{" "}
          </>
        )
      }}
    </PagesContext.Consumer>
  )
}
