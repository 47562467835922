import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { animated, useTrail } from "react-spring"
import styled from "styled-components"
import MagnifierIcon from "../../assets/magnifying_glass.svg"
import SymbolIcon from "../../assets/math_symbols.svg"
import PercentIcon from "../../assets/percent.svg"
import { respond } from "../../_respond"
import H2 from "../Headers/H2"
import TeaserCard from "./TeaserCard"

const obj = [
  {
    id: 1,
    icon: <SymbolIcon width="40px" height="40px" />,
    background: "#DCF6F0",
    title: "Kysymys",
    text: "Miksi meillä on lamoja ja työttömyyttä?",
    state: "",
  },
  {
    id: 2,
    icon: <MagnifierIcon width="40px" height="40px" />,
    background: "#E2DCF8",
    title: "Kysymys",
    text: "Miksi raaka-aineiden hinnat heiluvat niin paljon?",
    state: "active",
  },
  {
    id: 3,
    icon: <PercentIcon width="40px" height="40px" />,
    background: "#FBF6E0",
    title: "Kysymys",
    text: "Miksi asuminen on niin kallista?",
    state: "",
  },
]

const arr = [
  "Miksi meillä on lamoja ja työttömyyttä?",
  "Miksi meillä on lamoja ja työttömyyttä?",
  "Miksi asuminen on niin kallista?",
  "Mitä raha on?",
  "Mitä hyötyä on pankeista ja osakemarkkinoista?",
  "Mistä talouskasvu syntyy?",
  "Voidaanko ilmaston lämpeneminen pysäyttää taloudellisin keinoin?",
  "Eriarvoistuuko Suomi?",
  "Voiko talouskasvu jatkua loputtomiin?",
]

const Wrapper = styled.div`
  margin: 3rem 0 0 0;

  span {
    display: block;
    padding: 0.8rem 0;
    background: linear-gradient(160deg, #0093e9 0%, #80d0c7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    text-align: center;
  }

  H2 {
    font-family: "Josefin Sans", sans-serif;
    font-weight: 500;
    font-size: clamp(35px, 4vw, 50px);
    color: #333;
    text-align: center;
  }
`

const TeaserContainer = styled.div`
  padding: 2rem 0.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4rem;
  justify-content: center;

  ${respond.mobile`
  grid-gap: 0rem;
  padding: 0 0.5rem;
  grid-template-columns: minmax(20rem,100%);
`}

  ${respond.xs`
  grid-gap: 2rem;
  padding: 0 0.5rem;
  grid-template-columns: minmax(20rem,100%);
  `}

  ${respond.sm`
  width: 100%;
  padding: 0;
  grid-template-columns: repeat(3, minmax(15rem, 20rem));
  `}

  ${respond.md`
  padding: 2rem 2rem;
  grid-template-columns: repeat(3, 20rem);
  `}

  ${respond.lg`
  padding: 2rem 0;
  grid-template-columns: repeat(3, 24rem);
  grid-gap: 4rem;
  `}
`

const TeaserQuestion = (props) => {
  const [teaser, setTeaser] = useState({})
  const [willAnimate, setWillAnimate] = useState(false)
  const ourRef = useRef()

  useLayoutEffect(() => {
    const onScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight
      if (scrollPosition > 1700) {
        setWillAnimate(true)
      }
    }

    window.addEventListener("scroll", onScroll)
    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    checking(arr)
  }, [])

  const checking = (teaserArr) => {
    let newArr = [...teaserArr]

    let newTeaser = []
    for (let i = 0; newTeaser.length < 3; i++) {
      let teaser = newArr[Math.floor(Math.random() * 8)]

      /* check if teaser question already exist in array */
      if (!newTeaser.includes(teaser)) {
        newTeaser.push(teaser)
      }
    }
    setTeaser(newTeaser)
    return newTeaser.join("")
  }

  const trail = useTrail(obj.length, {
    marginTop: !willAnimate ? -100 : 20,
    opacity: !willAnimate ? 0 : 1,
    transform: !willAnimate
      ? "translate3d(0, -40px, 0)"
      : "translate3d(0, 0, 0)",
  })

  return (
    <Wrapper>
      <H2> {props.title} </H2>
      <TeaserContainer ref={ourRef}>
        {trail.map((props, index) => {
          return (
            <animated.div key={obj[index].id} style={props}>
              <TeaserCard
                index={index}
                icon={obj[index].icon}
                title={obj[index].title}
                text={teaser[index]}
                state={obj[index].state}
                background={obj[index].background}
              />
            </animated.div>
          )
        })}
      </TeaserContainer>
    </Wrapper>
  )
}

export default TeaserQuestion
