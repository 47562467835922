import React from "react"
import styled from "styled-components"

const Justify = styled.div`
  display: grid;
  justify-content: center;
`

const ContentWrapper = styled.div`
  padding: 4rem 2rem 4rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 12px 0px;
  margin: 4rem 0;
  max-width: 780px;
  border-left: 8px solid #bfbfbf;
`

const Text = styled.div`
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.6;
  font-style: italic;
`

export default (props) => {
  return (
    <Justify>
      <ContentWrapper>
        <Text> {props.children} </Text>{" "}
      </ContentWrapper>{" "}
    </Justify>
  )
}
