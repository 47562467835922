import React from "react"
import styled from "styled-components"

const ContentWrapper = styled.div`
  width: 340px;
  height: auto;
  margin: 0 auto;
`

export default (props) => {
  return <ContentWrapper>{props.children}</ContentWrapper>
}
