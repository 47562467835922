import React from "react"
import styled from "styled-components"

const Container = styled.div`
  padding: 10px;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
`

const LineColor = styled.span`
  color: ${(props) => props.color || "#32a852"};
`

const CustomTooltip = ({ chart, current, payload }) => {
  return (
    <Container>
      {payload.map((line, index) => {
        return current[index] ? (
          <div key={line.dataKey}>
            {" "}
            <LineColor color={line.stroke}>
              {line.name} : {line.value}
            </LineColor>
          </div>
        ) : null
      })}
    </Container>
  )
}

export default CustomTooltip
