import React from "react"
import styled from "styled-components"
import { respond } from "../../_respond"

const ContentWrapper = styled.div`
  width: 230px;
  height: ${(props) =>
    props.height !== undefined ? `${props.height}px` : "160px"};
  ${respond.mobile`
  padding: 0 1.2rem;
  `}
  ${respond.xs`
  padding: 0 1.2rem;
  `}
  ${respond.sm`
  padding: 0 0rem;
  `}
  ${respond.md`
  padding: 0 0rem;
  `}
  ${respond.lg`
  padding: 0 0rem;
  `}
  margin: 0 20px 5px 0;
  float: left;
  margin-top: 8px;

  span {
    margin-bottom: 0 !important;
  }

  p {
    padding-top: 3px;
    font-size: 13px;
    line-height: 1.5;
    font-style: italic;
  }
`

export default (props) => {
  return <ContentWrapper height={props.height}>{props.children}</ContentWrapper>
}
