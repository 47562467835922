import React from "react"
import styled from "styled-components"
import { respond } from "../../_respond"
import H2 from "../Headers/H2"
import Thumbnail from "./mooc_footer_graphic.png"

//TO-DO: + Iphone SE is breaking the design a bit (currently there's a quick-fix)

const PlaceHolderContainer = styled.div`
  background-size: url("mooc_footer_graphic.png");
  background-size: 100%;
  padding: 2rem 0rem;
  height: 90vh;
  position: relative;

  ${respond.mobile`
  min-height: 54rem;
`}

  ${respond.xs`
  height: 55rem;
`}

${respond.sm`
  height: 45rem;
`}

${respond.md`
  height: 40rem;
`}

  ${respond.lg`
  background-position: 80% 1em;
  height: 40rem;
  `}

  img {
    /*     position: absolute;
    right: 0;
    top: 0; */
    width: 100%;
    height: 115%;
    object-fit: cover;
  }
`
const PlaceHolderText = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
  text-align: left;
  color: #333;

  ${respond.mobile`
  padding-left: 1.2rem;
  padding-right: 1rem;
  width: 100%;
  top: 53%;
  left: 50%;
`}

  ${respond.xs`
  width: 80%;
`}

${respond.sm`
  width: 80%;
`}

${respond.md`
  width: 80%;
`}

  ${respond.lg`
  width: 80%;
  `}

  H2 {
    text-align: left;
    font-size: clamp(30px, 4vw, 50px);
    line-height: 1;
    font-family: "Josefin Sans", sans-serif;
  }

  span {
    font-size: 1.3rem;
    line-height: 1.7;
    color: #333;
    font-weight: 400;

    ${respond.mobile`
    font-size: 1rem;
    line-height: 1.45;
  `}

    ${respond.xs`
    font-size: 1.2rem;
  `}

    ${respond.lg`
    font-size: 1.3rem;
`}
  }
`

export default (props) => {
  return (
    <PlaceHolderContainer>
      <img src={Thumbnail} alt="Miten talous toimii?" aria-hidden="true" />
      <PlaceHolderText>
        <H2> {props.title} </H2> <span>{props.children}</span>{" "}
      </PlaceHolderText>{" "}
    </PlaceHolderContainer>
  )
}
