import React from "react"
import styled from "styled-components"
import { respond } from "../../_respond"
import DetailTag from "../DetailTag"
import H3 from "../Headers/H3"

//TO-DO: Make Grid responsive without Media queries
//TO-DO: + More Breakpoints

const Wrapper = styled.div`
  margin: 8rem auto;
  max-width: 60%;

  H3 {
    text-align: center;
  }

  span {
    display: block;
    text-align: center;
  }

  ${respond.mobile`
  max-width: 80%;
`}
`

const StyledLink = styled.a`
  color: #53ceaa;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  position: relative;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  line-height: 1.5rem;
  margin: 0.2rem 1.5rem;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #53ceaa;
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(0.86, 0, 0.07, 1);
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  :hover {
    text-decoration: none;
    color: #53ceaa;
  }
`

export default (props) => {
  return (
    <Wrapper>
      <H3>UKK</H3>
      <DetailTag>
        <details>
          <summary>Kuka on suunnitellut Talous-kurssin?</summary>
          <ul>
            <li>
              Kurssi on Taloustieteen keskus{" "}
              <a href="https://www.helsinkigse.fi/">Helsinki GSE:n</a>{" "}
              tutkijoiden ja työntekijöiden yhteinen ponnistus. Helsingin
              yliopiston tietojenkäsittelytieteiden laitos on vastannut kurssin
              teknisestä tuotannosta. Työssä ovat avustaneet Historian ja
              yhteiskuntaopin opettajien liitto{" "}
              <a href="http://www.hyol.fi/">HYOL</a> sekä lukuisat aktiiviset
              opiskelijat, opettajat ja taloustieteilijät. Katso tarkempi
              listaus <a href="/tekijat">tästä</a>. Kurssin valmistumista ovat
              tukeneet Aalto-yliopisto, Helsingin yliopisto ja Suomen Pankki.
              Kurssin levittämisessä viestintäkumppanina toimii OP.
            </li>
          </ul>
        </details>
        <details>
          <summary>
            Saanko kurssista jonkinlaisen todistusmerkinnän tai todistuksen?
          </summary>
          <ul>
            <li>
              Suoritettuasi kurssin, eli tehtyäsi kaikki tehtävät, sinulla on
              mahdollisuus saada kaksi opintopistettä Aalto-yliopiston tai
              Helsingin yliopiston avoimesta yliopistosta. Suoritettuasi kaikki
              tehtävät, löydät kurssin opintopisteiden rekisteröintilomakkeen
              minkä tahansa <a href="/part-1">pääluvun etusivulta</a>.
            </li>
          </ul>
        </details>
        <details>
          <summary>Millä tavalla kurssi seuraa edistymistäni?</summary>
          <ul>
            <li>
              Edistymistä seurataan tehtyjen kurssitehtävien kautta.Jokainen
              luku sisältää useita pähkinöitä. Voit muuttaa tai täydentää
              vastaustasi useamman kerran.Jokaisen tehtävän vastauksen ei
              tarvitse olla oikein, mutta tehtävän tulee olla suoritettu kurssin
              läpäisemiseksi. Löydät sivulta edistymistäsi seuraavan mittarin,
              jonka avulla saat tietää kurssista suorittamasi prosenttiosuuden.
            </li>
          </ul>
        </details>
      </DetailTag>
      <span>
        <StyledLink href="/usein-kysytyt-kysymykset">Katso lisää</StyledLink>
      </span>
    </Wrapper>
  )
}
