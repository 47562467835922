import React, { useState } from "react"
import styled from "styled-components"
import withSimpleErrorBoundary from "../../util/withSimpleErrorBoundary"
import LineChart from "./LineChart"

const StyledButton = styled.button`
  display: inline-block;
  width: auto;
  border-radius: 4px;
  padding: 14px 24px;
  color: #333;
  border: none;
  text-transform: none;
  outline: 0;
  background: #969696;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  backface-visibility: hidden;
  letter-spacing: normal;
  transform: translate3d(0, 0, 0);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.25s ease, box-shadow 0.25s ease;
  cursor: pointer;

  :hover {
    transform: translate3d(0, -2px, 0);
    box-shadow: 0 6px 7px 0 rgba(0, 0, 0, 0.15), 0 0 5px 0 rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }
`

const ChartSwitcher = ({ chart1, chart2, text1, text2 }) => {
  const [showChart, switchChart] = useState(true)
  const [buttonText, setButtonText] = useState(text1)

  const handleChartChange = () => {
    switchChart(!showChart)
    buttonText === text1 ? setButtonText(text2) : setButtonText(text1)
  }
  return (
    <div id="chart-switcher">
      {showChart === true ? (
        <LineChart chart={chart1}></LineChart>
      ) : (
        <LineChart chart={chart2}></LineChart>
      )}
      <StyledButton
        onClick={handleChartChange}
        aria-label={buttonText}
        role="button"
      >
        {buttonText}
      </StyledButton>
    </div>
  )
}
export default withSimpleErrorBoundary(ChartSwitcher)
