import React from "react"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

/* const Wrapper = styled.span`
display: block;
text-align: ${(props) => props.position};
font-size: 1rem;
font-style: italic;
` */

const Caption = (props) => {
  return (
    <span
      style={{
        padding: "1rem 0",
        display: "block",
        lineHeight: "1.6",
        textAlign: "center",
        fontSize: "1rem",
        fontFamily: "Poppins, sans-serif",
        opacity: "0.8",
        color: "#333",
        marginBottom: "1.2rem",
      }}
    >
      {props.children}
    </span>
  )
}

export default withSimpleErrorBoundary(Caption)
