import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import H2 from "../partials/Headers/H2"
import { postStoredData } from "../services/moocfi"
import encrypt from "../util/encryptor"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

const arr = {
  firstName: {
    label: "Etunimi",
    placeholder: "",
    type: "input",
    name: "first_name",
  },
  lastName: {
    label: "Sukunimi",
    placeholder: "",
    type: "input",
    name: "last_name",
  },
  email_address: {
    label: "Sähköpostiosoite",
    placeholder: "",
    type: "input",
    name: "email_address",
  },
  SSN: {
    label: "Henkilötunnus",
    placeholder: "",
    type: "input",
    name: "ssn",
  },
  nationality: {
    label: "Kansallisuus",
    placeholder: "",
    type: "input",
    name: "nationality",
  },
  gender: {
    label: "Sukupuoli",
    placeholder: "",
    type: "select",
    name: "gender",
  },
  phoneNumber: {
    label: "Puhelinnumero",
    placeholder: "",
    type: "input",
    name: "phone_number",
  },
  motherTongue: {
    label: "Äidinkieli",
    placeholder: "",
    type: "input",
    name: "mother_tongue",
  },
  address: {
    label: "Osoite",
    placeholder: "",
    type: "input",
    name: "address",
  },
  postalCode: {
    label: "Postinumero",
    placeholder: "",
    type: "input",
    name: "postal code",
  },
  city: {
    label: "Postitoimipaikka",
    placeholder: "",
    type: "input",
    name: "city",
  },
  organization: {
    label: "Koulu",
    placeholder: "",
    type: "input",
    name: "school",
  },
}

const Wrapper = styled.div`
  text-align: left !important;
  background: #fff;
  max-width: 600px;
  position: relative;
  margin: 0 auto;
  display: block;
  padding: 4rem 4rem;
  font-family: "Lato", sans-serif;
  box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  H2 {
    line-height: 1;
  }

  span {
    display: inline-block;
    margin-bottom: 2rem;
  }

  fieldset {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    margin-bottom: 10px;
  }

  label {
    color: #333;
    font-family: "Lato", sans-serif;
    margin-bottom: 5px;
    font-size: 14px;
    opacity: 0.9;
  }

  input {
    font-size: 16px;
    font-family: "Lato", sans-serif;
    padding: 0.25em 0.5em;
    background-color: #f9f9f9;
    border: 2px solid #eee;
    border-radius: 4px;
    transition: 180ms box-shadow ease-in-out;
    margin-bottom: 1rem;
  }

  input:focus {
    border-color: #52e3c2;
    box-shadow: 0 0 0 3px hsla(245, 100%, 245, 0.8);
    outline: 3px solid transparent;
  }

  input[type="submit"] {
    margin-top: 1rem;
    padding: 14px 34px;
    background: #52e3c2;
    color: #333;
    font-weight: 500;
    border-radius: 50px;
    font-family: "Josefin Sans", sans-serif;

    :hover {
      background: #48cfad;
    }

    :disabled {
      background: #e1e2e2;
    }
  }

  select {
    padding: 0.3em 0.5em;
    background-color: transparent;
    border: 2px solid #eee;
    border-radius: 4px;
    margin-bottom: 1rem;
    width: 90%;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.4;
    cursor: default;
  }
`

const ErrorMessage = styled.div`
  color: #ed5565;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 10px;
  padding: 0;
`

const RegisterForm = ({ onSuccess, organizationName }) => {
  const { register, formState, handleSubmit } = useForm({ mode: "onChange" })
  const { errors, isValid } = formState

  const [submitError, setSubmitError] = useState(false)

  console.log(errors, isValid)
  return (
    <Wrapper>
      <H2>Opintopisteiden rekisteröinti</H2>
      <span>
        Täyttämäsi tiedot lähetetään salattuna opintopisteiden kirjausta varten
      </span>

      {submitError && <div>Virhe lähetyksessä</div>}
      <form
        onSubmit={handleSubmit(async (data) => {
          try {
            const stringified = JSON.stringify(data)
            const encrypted = await encrypt(stringified)
            const res = await postStoredData(encrypted)
            onSuccess()
          } catch (error) {
            setSubmitError(true)
          }
        })}
      >
        <fieldset /* disabled={submitting} */>
          {Object.values(arr).map((item) => {
            const { name, label, type, placeholder } = item
            if (type === "input") {
              return (
                <div key={item.label}>
                  <label htmlFor={name}>{label}</label>
                  <input
                    value={name === "school" ? organizationName : null}
                    name={name}
                    placeholder={placeholder}
                    {...register(name, { required: "Kenttä on pakollinen" })}
                  />
                  {errors[name] && (
                    <ErrorMessage>
                      &#9888; {`${errors[name].message}`}
                    </ErrorMessage>
                  )}
                </div>
              )
            } else {
              return (
                <div key={item.label}>
                  <label htmlFor={name}>{label}</label>
                  <select
                    name={name}
                    {...register(name, { required: "Kenttä on pakollinen" })}
                  >
                    <option value="">Valitse sukupuoli</option>
                    <option value="male">Mies</option>
                    <option value="female">Nainen</option>
                    <option value="other">Muu</option>
                  </select>
                  {errors[name] && (
                    <ErrorMessage>
                      &#9888; {`${errors[name].message}`}
                    </ErrorMessage>
                  )}
                </div>
              )
            }
          })}
        </fieldset>
        <input
          disabled={!isValid}
          value="Lähetä"
          label="Lähetä"
          type="submit"
        />
      </form>
    </Wrapper>
  )
}

export default withSimpleErrorBoundary(RegisterForm)
